import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
  Switch,
  Popover,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { setMessage, clearMessage } from "slices/message";
import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import FlexBetween from "components/global/FlexBetween";
import DetailBlock from "components/global/DetailBlock";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import { RefreshOutlined, StopOutlined } from "@mui/icons-material";

import TabPanel from "components/global/TabPanel";
import KeyVal from "components/global/KeyVal";
import { now } from "moment";
import TablePage from "components/global/TablePage";
import NiceUser from "components/global/NiceUser";

import ObjectDetails from "components/global/ObjectDetails";
import TabsVertical from "components/global/TabsVerticle";
import TabVertical from "components/global/TabVertical";
import { listAuditLog } from "slices/auditLog";
import { getService } from "slices/services";
import WhereUsedService from "components/where_used/WhereUsedService";
import {
  getCatalogue,
  getCataloguePageUi,
  getCataloguePages,
} from "slices/catalogue";
import { renderColumn } from "common/renderColumn";
import { processString } from "common/helpers";
import NiceLink from "components/global/NiceLink";
import CataloguePageAdd from "components/catalogue/CataloguePageCreate";

const AdminCatalogue = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const catalogue = useSelector((state) =>
    state.catalogue.catalogue.data.list?.find((element) => element.id === id)
  );

  const pages = useSelector((state) => state.catalogue.page.data);

  const auditLogRows = useSelector((state) => state.auditLog.data);
  const pageUi = useSelector((state) => state.catalogue.page.Ui);

  useEffect(() => {
    //need to get the UI form the API
    dispatch(getCataloguePageUi({}));
  }, [pages, dispatch]);

  //console.log("Log:", ServiceLog)

  const [tabValue, setTabValue] = React.useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const setTabValueAndUpdateQuery = (newValue) => {
    // Update the state
    setTabValue(newValue);

    // Update the query string
    const queryParams = new URLSearchParams(searchParams.toString());
    queryParams.set("tab", newValue);
    setSearchParams(queryParams.toString(), { replace: true });

    //console.log("setting tab:", queryParams.toString());
  };

  const handleTabChange = (event, newValue) => {
    setTabValueAndUpdateQuery(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  useInterval(() => {}, 1000);

  useEffect(() => {
    if (!isFirstLoad) {
      //need to get the Service form the API
      dispatch(getService({ id }));
      setIsFirstLoad(false);

      if (searchParams.get("tab")) {
        setTabValue(parseInt(searchParams.get("tab"), 10));
      } else {
        setTabValueAndUpdateQuery(tabValue);
      }
    }
  }, [id]);

  useEffect(() => {
    dispatch(getCatalogue({ id }));
  }, [refreshPoint]);

  const logColumns = [
    {
      field: "when",
      headerName: "Last Chnaged",
      flex: 1,
      renderCell: ({ row: { when } }) => {
        return <NiceDate>{when}</NiceDate>;
      },
    },
    {
      field: "username",
      headerName: "User",
      flex: 1,
      renderCell: ({ row: { username } }) => {
        return <NiceUser>{username}</NiceUser>;
      },
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 1,
    },
    {
      field: "fieldName",
      headerName: "Field",
      flex: 1,
    },
    {
      field: "fieldUpdate",
      headerName: "Change",
      flex: 1,
    },
    {
      field: "ipAddress",
      headerName: "From",
      flex: 1,
    },
  ];

  const columnsVisability = {};

  let pageColumns = [];

  if (pageUi?.columns) {
    pageColumns = pageUi.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //debugger;

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
        }
      }

      return updatedColumn;
    });
  }

  const [selectionModel, setSelectionModel] = React.useState([]);

  const pageColumsVisability = {
    ...pageUi.columnsVisability,
    catalogueId: false,
  };

  const [anchorElPage, setAnchorElPage] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("30vw");
  const configRef = React.useRef(null);

  const handelOpenAddEditPage = () => {
    dispatch(clearMessage());
    setAnchorElPage(configRef.current);
  };

  const handelCloseAddEditPage = () => {
    setAnchorElPage(null);
  };

  const openPage = Boolean(anchorElPage);

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs
          lastOverride={catalogue?.type + " - " + catalogue?.name}
        />

        <FlexBetween>
          <Header title={catalogue?.name} subtitle={catalogue?.type} />
          <FlexBetween gap=".5rem">
            <Button variant="outlined" color="primary" onClick={handelRefresh}>
              <RefreshOutlined />
            </Button>

            {tabValue === 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handelOpenAddEditPage}
                ref={configRef}
              >
                New Page
              </Button>
            )}
          </FlexBetween>
        </FlexBetween>

        <Box mt="20px" sx={{ width: "100%" }}>
          <Box
            gap="1rem"
            sx={{
              //borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
              width: "100%",
              display: "flex",
              flexGrow: 1,
            }}
          >
            <TabsVertical
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Service Tabs"
            >
              <TabVertical label={"Details"} {...a11yProps(0)} />
              <TabVertical
                label={`Pages (${pages?.total})`}
                {...a11yProps(1)}
              />
              <TabVertical
                label={`Log (${auditLogRows?.total})`}
                {...a11yProps(2)}
              />
            </TabsVertical>

            <TabPanel value={tabValue} index={0}>
              {id !== undefined ? (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  gap="1rem"
                >
                  <ObjectDetails
                    serviceType={"catalogue"}
                    id={id}
                    rowId={null}
                    refreshPoint={refreshPoint}
                    titleSetter={null}
                  />
                </Box>
              ) : (
                <Typography>Nothing to load...</Typography>
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <TablePage
                masterId={id}
                masterIdField="catalogueId"
                dataRows={pages}
                dataColumns={pageColumns}
                dataGetter={getCataloguePages}
                defaultColumnsVisability={pageColumsVisability}
                defaultSort={"sequence"}
                refreshPoint={refreshPoint}
                serviceType={"cataloguePage"}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              {auditLogRows ? (
                <TablePage
                  masterId={id}
                  masterIdField="objectId"
                  dataRows={auditLogRows}
                  dataColumns={logColumns}
                  dataGetter={listAuditLog}
                  defaultColumnsVisability={columnsVisability}
                  defaultSort={"-when"}
                  refreshPoint={refreshPoint}
                  serviceType={"auditLog"}
                  initialFilter={"[operation]=Create,Update"}
                />
              ) : (
                <Typography>Empty Log...</Typography>
              )}
            </TabPanel>
          </Box>
        </Box>
      </PageBlock>

      <Popover
        open={openPage}
        anchorEl={anchorElPage}
        onClose={handelCloseAddEditPage}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <CataloguePageAdd
            closerFunc={handelCloseAddEditPage}
            //cataloguePage={cataloguePage}
            catalogue={id}
          />
        </Box>
      </Popover>
    </>
  );
};

export default AdminCatalogue;
