import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AttachmentService from "../services/attachment.service";

const list = {
  list: [],
  pageSize: 25,
  page: 0,
  total: 0,
  nextPage: 0,
};


export const createAttachement = createAsyncThunk(
  "Attachement/create",
  async ({ attachment }, thunkAPI) => {
    try {
      //debugger
      const response = await AttachmentService.createAttachment(attachment);


      
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getAttachments = createAsyncThunk(
  "Attachment/get_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await AttachmentService.getAttachments(search);
      return { attachments: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAttachmentUsedBy = createAsyncThunk(
  "Attachment/get_list_usedBys",
  async ({ search }, thunkAPI) => {
    try {
      const data = await AttachmentService.getAttachmentUsedBy(search);
      return { attachments: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getAttachmentByUser = createAsyncThunk(
  "Attachment/get_list_byUser",
  async ({ search }, thunkAPI) => {
    try {
      const data = await AttachmentService.getAttachmentByUser(search);
      return { attachments: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getAttachment = createAsyncThunk(
  "Attachment/get",
  async ({ id }, thunkAPI) => {
    try {
      const data = await AttachmentService.getAttachment(id);
      return { attachment: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAttachmentUi = createAsyncThunk(
  "Attachment/get_ui",
  async ({ id }, thunkAPI) => {
    try {
      const data = await AttachmentService.getAttachmentUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAttachmentUsedByUi = createAsyncThunk(
  "Attachment/get_usedby_ui",
  async ({ id }, thunkAPI) => {
    try {
      const data = await AttachmentService.getAttachmentUsedByUi(id);
      return { ui: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getAttachmentFilterValues = createAsyncThunk(
  "Attachment/get_filter_values_list",
  async ({ search }, thunkAPI) => {
    try {
      const data = await AttachmentService.getAttachmentFilterValues(search);
      return { filter_values: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteAttachment= createAsyncThunk(
  "Attachment/delete",

  async ({ id }, thunkAPI) => {
    try {
      const data = await AttachmentService.deleteAttachment(id);
      return { Attachment: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message:message, type:"error"}));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAttachmentCategories = createAsyncThunk(
  "attachmnets/get_categories",
  async (_, thunkAPI) => {
    try {
      const data = await AttachmentService.getAttachmentCategories();
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  data: list,
  loading: false,
  attachmentUi: [],
  filter_values: [],
  Ui: [],
  categories: [],
  usedBys: list,
  usedByUi: [],
};

const AttachmentSlice = createSlice({
  name: "attachments",
  initialState,

  extraReducers: {

    [deleteAttachment.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAttachment.fulfilled]: (state, action) => {
      // Filter out the data that matches the id in the action payload

      //debugger
      const updatedPageList = state.data.list.filter((attachment) => attachment.id !== action.payload.Attachment.id);
      
      // Update the state with the filtered list
      state.data.list = updatedPageList;
      
      // Set loading to false
      state.loading = false;
    },
    
    [deleteAttachment.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    [getAttachmentCategories.pending]: (state, action) => {
      state.loading = true;
    },
    [getAttachmentCategories.fulfilled]: (state, action) => {
      //debugger;
      
      state.categories = action.payload.data.categories;
      state.loading = false;
    },
    [getAttachmentCategories.rejected]: (state, action) => {

      state.categories = null;
      state.loading = false;

      
    },

    
    [createAttachement.pending]: (state, action) => {
      state.loading = true;
    },
    [createAttachement.fulfilled]: (state, action) => {

      state.data.list.push(action.payload.response.data);
      state.data.total ++;
      state.loading = false;
    },
    [createAttachement.rejected]: (state, action) => {
      state.loading = false;
    },



    [getAttachments.pending]: (state, action) => {
      state.loading = true;
    },
    [getAttachments.fulfilled]: (state, action) => {

      //debugger

      state.data = action.payload.attachments;
      state.loading = false;
    },
    [getAttachments.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },



    [getAttachmentByUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getAttachmentByUser.fulfilled]: (state, action) => {

      //debugger

      state.data = action.payload.attachments;
      state.loading = false;
    },
    [getAttachmentByUser.rejected]: (state, action) => {
      state.data = list;
      state.loading = false;
    },

    
[getAttachmentUsedBy.pending]: (state, action) => {
      state.loading = true;
    },
    [getAttachmentUsedBy.fulfilled]: (state, action) => {

      //debugger

      state.usedBys  = action.payload.attachments;
      state.loading = false;
    },
    [getAttachmentUsedBy.rejected]: (state, action) => {
      state.usedBys = list;
      state.loading = false;
    },


    [getAttachmentUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getAttachmentUi.fulfilled]: (state, action) => {
      state.Ui = action.payload.ui;
      state.loading = false;
    },
    [getAttachmentUi.rejected]: (state, action) => {
      state.Ui = null;
      state.loading = false;
    },

    [getAttachmentUsedByUi.pending]: (state, action) => {
      state.loading = true;
    },
    [getAttachmentUsedByUi.fulfilled]: (state, action) => {
      state.usedByUi = action.payload.ui;
      state.loading = false;
    },
    [getAttachmentUsedByUi.rejected]: (state, action) => {
      state.usedByUi = null;
      state.loading = false;
    },


    [getAttachmentFilterValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getAttachmentFilterValues.fulfilled]: (state, action) => {
      //debugger;

      state.filter_values = action.payload.filter_values;
      state.loading = false;
    },
    [getAttachmentFilterValues.rejected]: (state, action) => {
      state.filter_values = list;
      state.loading = false;
    },


    [getAttachment.pending]: (state, action) => {
      state.loading = true;
    },
    [getAttachment.fulfilled]: (state, action) => {

      //debugger;

      const newPayload = action.payload.attachment;

      const existingIndex = state.data.list.findIndex(
        payload => payload.id === newPayload.id
      );
    
      if (existingIndex >= 0) {
        // The payload already exists, replace it
        state.data.list[existingIndex] = newPayload;
      } else {
        // The payload doesn't exist, add it
        state.data.list.push(newPayload);
      }


      state.loading = false;
    },
    [getAttachment.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { reducer } = AttachmentSlice;
export default reducer;
