import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Box, Button, TextField, Stack, MenuItem, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { clearMessage } from "slices/message";
import Header from "components/global/Header";
import { createAttachement, getAttachmentCategories } from "slices/attachment";

// Function to convert file to Base64
const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]); // Extract only the base64 part
    reader.onerror = (error) => reject(error);
  });
};

const AttachmentCreate = ({ closerFunc }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const attachmentCattegory = useSelector((state) => state.attachment.categories);
  const [fileBase64, setFileBase64] = useState(""); // State to store the Base64 file data
  const [fileName, setFileName] = useState(""); // State to store the file name

  useEffect(() => {
    if (!attachmentCattegory || attachmentCattegory.length === 0) {
      dispatch(getAttachmentCategories());
    }
  }, [dispatch, attachmentCattegory]);

  const initialValues = {
    name: "",
    category: "",
    description: "",
    content: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    category: Yup.string().required("This field is required"),
  });

  return (
    <Box m="20px">
      <Header title={"New Attachment"} />

      <Formik
        onSubmit={async (values, actions) => {
          const attachment = { ...values, content: fileBase64 }; // Add the Base64 file data to the payload

          dispatch(clearMessage());

          try {
            await dispatch(createAttachement({ attachment })).unwrap();
            closerFunc();
          } catch {
            actions.setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >

               {/* Custom File Input */}
               <input
                type="file"
                id="file-upload"
                style={{ display: "none" }} // Hide the default file input
                onChange={async (e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setFieldValue("file", file); // Set the file in Formik state
                    setFieldValue("name", file.name); // Set the "Name" field with the file name
                    const base64 = await convertFileToBase64(file);
                    setFileBase64(base64); // Store Base64 string in state
                    setFileName(file.name);
                  }
                }}
                onBlur={handleBlur}
                name="file"
                accept=".png,.jpg,.jpeg,.pdf,.docx,.xlsx" 
              />
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                >
                  Select File
                </Button>
              </label>
              {fileName && (
                <Typography>
                  Selected File: {fileName}
                </Typography>
              )}


              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 1" }}
                disabled={!fileName}
              />

              <TextField
                fullWidth
                select
                variant="outlined"
                type="string"
                label="Category"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category}
                name="category"
                error={!!touched.category && !!errors.category}
                helperText={touched.category && errors.category}
                sx={{ gridColumn: "span 1" }}
                disabled={!fileName}
              >
                {attachmentCattegory?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 1" }}
                disabled={!fileName}
              />
            
             
            </Box>

            <Box display="flex" justifyContent="end" mt="2rem" mb="2rem">
              <Stack direction="row" spacing={2} mb="2rem">
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={closerFunc}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Add Attachment
                </Button>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AttachmentCreate;
