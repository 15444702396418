// Simple wrapper to colapse the field to a non overflow
const NiceId = (props) => {

  //debugger

  return (

    <span
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
      }}
    >
      {props.children}
    </span>

  );
}

export default NiceId;
