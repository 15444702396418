import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  IconButton,
  Tooltip,
  Popover,
} from "@mui/material";
import {
  GridColumns,
  GridCellParams,
  GridRowsProp,
  DataGrid,
  GridCellModesModel,
  GridCellModes,
} from "@mui/x-data-grid";

import {
  WidgetsOutlined,
  PlayCircleOutlined,
  ToggleOffOutlined,
  ToggleOnOutlined,
  RefreshOutlined,
  ArrowDropDown,
  DeleteOutline,
  PlayArrowOutlined,
  CheckOutlined,
  CompressOutlined,
  CloseOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect, useMemo } from "react";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunities, getOpportunity } from "slices/opportunity";
import { setMessage, clearMessage } from "slices/message";
import NiceOrganization from "components/global/NiceOrganization";
import NiceOrchestrator from "components/global/NiceOrchestrator";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import TableActionsButton from "components/global/TableActionsButton";
import NiceLink from "components/global/NiceLink";

import { renderColumn } from "common/renderColumn";
import { getAuditLogUi, listAuditLog } from "slices/auditLog";

const AuditLogs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.auditLog.data);
  const ui = useSelector((state) => state.auditLog.Ui);

  useEffect(() => {
    //need to get the UI form the API
    dispatch(getAuditLogUi({}));
  }, [rows]);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };


  const configRef = React.useRef(null);




  const [selectionModel, setSelectionModel] = React.useState([]);


  const handleOpen = () => {
    navigate("/auditlog/" + selectionModel[0]);
  };

  const actionMenuItems = [
    {
      text: "Details",
      icon: <OpenInNewOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
    },

  ];


  let columns = [];

  const processString = (inputString, row) => {
    const regex = /<([^>]+)>/g;

    inputString = inputString.replace(/_id/g, "id");

    const processedString = inputString.replace(regex, (match, placeholder) => {
      if (row.hasOwnProperty(placeholder)) {
        return row[placeholder];
      } else {
        return match;
      }
    });

    return processedString;
  };

  if (ui?.columns) {
    columns = ui.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //debugger;

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
        }
      }

      return updatedColumn;
    });
  }

  console.log("data data", rows , " " , ui)

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs />
        <FlexBetween>
          <Header
            title="Audit Log"
            subtitle=""
            count={rows.total}
          />
          <FlexBetween gap=".5rem">
            <Button
              variant="outlined"
              color="primary"
              onClick={handelRefresh}
            >
              <RefreshOutlined />
            </Button>

            <TableActionsButton
              selectedRows={selectionModel}
              menuItems={actionMenuItems}
            />

          </FlexBetween>
        </FlexBetween>

        <Box ref={configRef} />

        {rows && ui?.columns?.length > 0 ? (

          
          <TablePage
            dataRows={rows}
            dataColumns={columns}
            dataGetter={listAuditLog}
            defaultColumnsVisability={ui.columnsVisability}
            defaultSort={ui.sort}
            refreshPoint={refreshPoint}
            selectionModel={selectionModel}
            selectionSetter={setSelectionModel}
            serviceType={"auditLog"}
            initialFilter={"[serviceType]=!=auditLog|[userName]=!=SERVICE"}
          />
        ) : (
          <Typography>Loading</Typography>
        )}
      </PageBlock>
    </>
  );
};

export default AuditLogs;
