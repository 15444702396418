import * as React from "react";
import Box from "@mui/material/Box";
import { useSpring, animated } from "@react-spring/web";
import SvgIcon from "@mui/material/SvgIcon";

import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import {
  AddOutlined,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  DeleteOutlined,
  EditOutlined,
  GetAppOutlined,
} from "@mui/icons-material";
import { IconButton, Popover, Tooltip, Typography } from "@mui/material";
import FlexBetween from "../global/FlexBetween";
import StateSetFieldCreateEdit from "./StateSetFieldCreateEdit";
import { useState } from "react";
import { string } from "yup";
import { useDispatch } from "react-redux";
import { clearMessage } from "slices/message";
import { updateStateSetRowSchema } from "slices/state";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function EditSquare(props) {
  return (
    <SvgIcon
      className="edit"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
    </SvgIcon>
  );
}

function ArrowCircle(props) {
  return (
    <SvgIcon
      className="edit"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22,12c0-5.52-4.48-10-10-10C6.48,2,2,6.48,2,12s4.48,10,10,10C17.52,22,22,17.52,22,12z M4,12c0-4.42,3.58-8,8-8 c4.42,0,8,3.58,8,8s-3.58,8-8,8C7.58,20,4,16.42,4,12z M16,12l-4,4l-1.41-1.41L12.17,13H8v-2h4.17l-1.59-1.59L12,8L16,12z" />
    </SvgIcon>
  );
}

function Square(props) {
  return (
    <SvgIcon
      className="edit"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M3,3v18h18V3H3z M19,19H5V5h14V19z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const CustomTreeItem = React.forwardRef((props, ref) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
));

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const RowSchemaDisplay = ({ data, stateSetId, configRef}) => {
  const dataObject = JSON.parse(data);

  const properties = dataObject["$jsonSchema"]["properties"];

  const propertiesArray = Object.entries(properties);

  // Sort the array based on the 'sequence' field
  propertiesArray.sort((a, b) => {
    const sequenceA = parseInt(a[1].sequence, 10);
    const sequenceB = parseInt(b[1].sequence, 10);
    return sequenceA - sequenceB;
  });

  // Convert the sorted array back into an object
  const sortedProperties = Object.fromEntries(propertiesArray);

  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeFields, setSelectedNodeFields] = useState(null);
  const [createEditMode, setcreateEditMode] = useState("NEW");

  const [anchorEl, setAnchorEl] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("50vw");

  

  const handelOpenAddField = (node, sequence) => {
    //debugger;

    setSelectedNodeFields(sortedProperties[node]);

    setSelectedNode(node);
    setcreateEditMode("NEW");

    setAnchorEl(configRef.current);
  };

  const handelOpenEditField = (node, sequence) => {
    

    setSelectedNodeFields(sortedProperties[node]);
    setSelectedNode(node);
    setcreateEditMode("EDIT");

    setAnchorEl(configRef.current);
  };

  const handelCloseAddField = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const nodeCount = Object.keys(sortedProperties).length;

  //console.log("Nodes: " , nodeCount)

  const dispatch = useDispatch();

  const handleDelete = (node) => {
    // Remove the field from the object
    delete sortedProperties[node];

    // Renumber the sequences
    let newSequence = 1;
    for (const key in sortedProperties) {
      sortedProperties[key].sequence = newSequence.toString();
      newSequence++;
    }

    const reconstructedObject = {
      $jsonSchema: {
        bsonType: "object",
        properties: { ...sortedProperties },
      },
    };

    // Convert the reconstructed object to a JSON string
    const jsonString = JSON.stringify(reconstructedObject, null, 2);

    const schema = {
      stateSetId: stateSetId,
      rowSchema: jsonString,
    };

    dispatch(clearMessage());
    dispatch(updateStateSetRowSchema({ schema }));
  };

  const handleMoveUp = (currentNode, currentSequence) => {
    // Find the previous node in the sequence
    let previousNodeKey = null;
    for (const key in sortedProperties) {
      const node = sortedProperties[key];
      const sequence = parseInt(node.sequence, 10);

      if (sequence === currentSequence - 1) {
        previousNodeKey = key;
        break;
      }
    }

    if (previousNodeKey) {
      // Swap sequences of the current and previous nodes
      const tempSequence = sortedProperties[currentNode].sequence;
      sortedProperties[currentNode].sequence =
        sortedProperties[previousNodeKey].sequence;
      sortedProperties[previousNodeKey].sequence = tempSequence;
    }

    const reconstructedObject = {
      $jsonSchema: {
        bsonType: "object",
        properties: { ...sortedProperties },
      },
    };

    // Convert the reconstructed object to a JSON string
    const jsonString = JSON.stringify(reconstructedObject, null, 2); // The third argument (2) is for pretty-printing with indentation

    const schema = {
      stateSetId: stateSetId,
      rowSchema: jsonString,
    };

    dispatch(clearMessage());
    dispatch(updateStateSetRowSchema({ schema }));
  };
  const handleMoveDown = (currentNode, currentSequence) => {
    // Find the next node in the sequence
    let nextNodeKey = null;
    for (const key in sortedProperties) {
      const node = sortedProperties[key];
      const sequence = parseInt(node.sequence, 10);

      if (sequence === currentSequence + 1) {
        nextNodeKey = key;
        break;
      }
    }

    if (nextNodeKey) {
      // Swap sequences of the current and next nodes
      const tempSequence = sortedProperties[currentNode].sequence;
      sortedProperties[currentNode].sequence =
        sortedProperties[nextNodeKey].sequence;
      sortedProperties[nextNodeKey].sequence = tempSequence;
    }

    const reconstructedObject = {
      $jsonSchema: {
        bsonType: "object",
        properties: { ...sortedProperties },
      },
    };

    // Convert the reconstructed object to a JSON string
    const jsonString = JSON.stringify(reconstructedObject, null, 2);

    const schema = {
      stateSetId: stateSetId,
      rowSchema: jsonString,
    };

    dispatch(clearMessage());
    dispatch(updateStateSetRowSchema({ schema }));
  };

  let previousNode = sortedProperties[0];

  return (
      <Box sx={{ minHeight: 270, flexGrow: 1, maxWidth: "600px" }}>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handelCloseAddField}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box height={areaHeight} width={areaWidth}>
            <StateSetFieldCreateEdit
              closerFunc={handelCloseAddField}
              currentNode={selectedNodeFields}
              stateSetId={stateSetId}
              mode={createEditMode}
              anchorNode={selectedNode}
            />
          </Box>
        </Popover>

        <TreeView
          aria-label="customized"
          defaultExpanded={["1"]}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<Square />}
          sx={{ overflowX: "hidden" }}
        >
          <StyledTreeItem nodeId="1" label={<Typography fontWeight={"bold"}>Schema</Typography>}>
            {Object.keys(sortedProperties)?.map((propertyName) => {
              const propertyValue = sortedProperties[propertyName];
              const sequence = parseInt(
                sortedProperties[propertyName].sequence
              );

              let moveUpAllowed = true;
              if (
                previousNode === "_id" ||
                previousNode === "organization" ||
                previousNode === "orchestrator" || 
                previousNode === "tokens" || 
                previousNode === "embeddings" 
              ) {
                moveUpAllowed = false;
              }
              previousNode = propertyName;

              return (
                <StyledTreeItem
                  key={`node_key_${propertyName}`}
                  nodeId={`node_id_${propertyName}`}
                  label={
                    <Box
                      display="flex"
                      alignContent="center"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography fontWeight={"bold"}>{propertyName}</Typography>

                      <Box
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                      >
                        <IconButton
                          size="small"
                          aria-label="Edit"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event propagation
                            handelOpenEditField(propertyName);
                          }}
                        >
                          <EditOutlined fontSize="small" />
                        </IconButton>

                        {sequence !== 1 &&
                        propertyName !== "_id" &&
                        propertyName !== "organization" &&
                        propertyName !== "orchestrator" &&
                        propertyName !== "tokens" &&
                        propertyName !== "embeddings"  &&
                        moveUpAllowed ? (
                          <Tooltip title="Move Field up">
                            <IconButton
                              size="small"
                              aria-label="Move Up"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent event propagation
                                handleMoveUp(propertyName, sequence);
                              }}
                            >
                              <ArrowUpwardOutlined fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <IconButton
                            size="small"
                            aria-label="Move Up"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event propagation
                              handleMoveUp(propertyName, sequence);
                            }}
                            disabled={true}
                          >
                            <ArrowUpwardOutlined fontSize="small" />
                          </IconButton>
                        )}

                        {sequence !== nodeCount &&
                        propertyName !== "_id" &&
                        propertyName !== "organization" &&
                        propertyName !== "orchestrator" &&
                        propertyName !== "tokens" &&
                        propertyName !== "embeddings"  
                        ? (
                          <Tooltip title="Move Field down">
                            <IconButton
                              size="small"
                              aria-label="Move Down"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent event propagation
                                handleMoveDown(propertyName, sequence);
                              }}
                            >
                              <ArrowDownwardOutlined fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <IconButton
                            size="small"
                            aria-label="Move Down"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event propagation
                              handleMoveDown(propertyName, sequence);
                            }}
                            disabled={true}
                          >
                            <ArrowDownwardOutlined fontSize="small" />
                          </IconButton>
                        )}

                        {propertyName !== "_id" &&
                        propertyName !== "organization" &&
                        propertyName !== "orchestrator" &&
                        propertyName !== "tokens"  ? (
                          <>
                            <IconButton
                              size="small"
                              aria-label="Add Below"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent event propagation
                                handelOpenAddField(propertyName, sequence);
                              }}
                            >
                              <AddOutlined fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              aria-label="Delete"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent event propagation
                                handleDelete(propertyName);
                              }}
                            >
                              <DeleteOutlined fontSize="small" />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              size="small"
                              aria-label="delete"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent event propagation
                                handelOpenAddField(propertyName, sequence);
                              }}
                              disabled={propertyName === "_id" ||
                              propertyName === "organization" || 
                              propertyName !== "orchestrator" ||
                              propertyName !== "tokens" ||
                              propertyName !== "embeddings"}
                            >
                              <AddOutlined fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              aria-label="Delete"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent event propagation
                                handleDelete(propertyName);
                              }}
                              disabled={true}
                            >
                              <DeleteOutlined fontSize="small" />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </Box>
                  }
                >
                  {Object.entries(propertyValue).map(([key, value]) => (
                    <StyledTreeItem
                      key={key}
                      nodeId={key}
                      label={`${key}: ${value}`}
                    />
                  ))}
                </StyledTreeItem>
              );
            })}
          </StyledTreeItem>
        </TreeView>
      </Box>
  );
};

export default RowSchemaDisplay;
