import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardContent,
  CardActions,
  Divider,
  Stack,
  TextField,
  MenuItem,
  Popover,
} from "@mui/material";
import { now } from "moment";
import { getAttachmentCategories, getAttachments } from "slices/attachment";
import { Link, useNavigate } from "react-router-dom";
import {
  AttachFileOutlined,
  DownloadOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import KeyVal from "components/global/KeyVal";
import Header from "components/global/Header";
import FlexBetween from "components/global/FlexBetween";
import AttachmentCreate from "./AttachmentCreate";

const AttachmentPicker = ({
  closerFunc,
  useButtonName,
  addAttachmentHandler,
}) => {
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState("");
  const attachmentCattegory = useSelector(
    (state) => state.attachment.categories
  );


  // Add the "all" category to the list
  const categoriesWithAll = ['All', ...attachmentCattegory];

  const configRef = React.useRef(null);

  const handelOpenAddEdit = () => {
    setAnchorEl(configRef.current);
  };

  //add edit statset
  const [anchorEl, setAnchorEl] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("30vw");
  const open = Boolean(anchorEl);

  const handelCloseAddEdit = () => {
    setAnchorEl(null);
  };

  const attachments = useSelector((state) => state.attachment.data);

  //debugger
  const [refreshPoint, setRefreshPoint] = useState(now());

  useEffect(() => {
    if (!attachmentCattegory || attachmentCattegory.length === 0) {
      dispatch(getAttachmentCategories());
    }
  }, [dispatch, attachmentCattegory]);

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  useEffect(() => {

    const categoryFilter = selectedCategory === "All" ? "" : selectedCategory;

    const search = {
      filter: "[category]=" + categoryFilter,
      sort: "name",
    };
    dispatch(getAttachments({ search }));
  }, [refreshPoint, dispatch, selectedCategory]);

  return (
    <>
      <Box display={"flex"} margin={1} flexDirection={"column"} height={"100%"}>
        <FlexBetween>
          <Header title={"Pick Attachment"} />

          <FlexBetween gap={2}>
            <TextField
              sx={{ width: "300px" }}
              select
              size="small"
              variant="outlined"
              type="string"
              label="Category"
              onChange={(event) => setSelectedCategory(event.target.value)}
              value={selectedCategory}
              name="category"
            >
              {categoriesWithAll?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <Box ref={configRef} />

            <FlexBetween gap=".5rem">
              <Button
                variant="outlined"
                color="primary"
                onClick={handelRefresh}
              >
                <RefreshOutlined />
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handelOpenAddEdit}
              >
                New Attachment
              </Button>
            </FlexBetween>
          </FlexBetween>
        </FlexBetween>

        {attachments?.total > 0 ? (
          <Box
            mt={"1rem"}
            sx={{
              flexGrow: 1, // Allow the box to grow and take available space
              overflow: "auto", // Enable scrolling
              maxHeight: "55vh", // Set a max height for the scrolling area
            }}
          >
            <Grid container spacing={2}>
              {attachments?.list.map((attachment) => (
                <AttachmentCard
                  key={attachment.id}
                  attachment={attachment}
                  useButtonName={useButtonName}
                  addAttachmentHandler={addAttachmentHandler}
                />
              ))}
            </Grid>
          </Box>
        ) : (
          <Typography>Loading</Typography>
        )}

        <Box display={"flex"} justifyContent={"flex-end"} mt={1} mb={1}>
          <Button variant="contained" color="error" onClick={closerFunc}>
            Cancel
          </Button>
        </Box>
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handelCloseAddEdit}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <AttachmentCreate closerFunc={handelCloseAddEdit} />
        </Box>
      </Popover>
    </>
  );
};

export default AttachmentPicker;

const AttachmentCard = ({
  attachment,
  useButtonName,
  addAttachmentHandler,
}) => {
  let icon = <AttachFileOutlined />;

  //console.log("cataloguys by type: ", attachmentType, " ", catalogusByType);
  //debugger

  return (
    <>
      <Grid item xs={12} sm={6} md={3} key={attachment.id}>
        <Card key={attachment.id} sx={{ maxWidth: 270 }} variant="outlined">
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "primary.main", width: 30, height: 30 }}>
                {icon}
              </Avatar>
            }
            // action={
            //   <IconButton aria-label="settings">
            //     <MoreVertOutlined />
            //   </IconButton>
            // }
            title={attachment.name}
            titleTypographyProps={{ variant: "h5", color: "primary" }}
            subheaderTypographyProps={{ variant: "h5" }}
            sx={{ alignItems: "flex-start" }} // Align items to the top
          />

          <CardContent>
            <Typography>{attachment.description}</Typography>

            <Divider flexItem sx={{ mt: ".3rem", mb: ".3rem" }} />
            <KeyVal label={"Category"} labelBold value={attachment.category} />
            <KeyVal
              label={"Created By"}
              labelBold
              value={attachment.createdBy}
              uiRenderType={"<NiceUser>"}
            />
            <KeyVal
              label={"Created"}
              labelBold
              value={attachment.createdAt}
              uiRenderType={"<NiceDate>"}
            />
            <KeyVal
              label={"Size"}
              labelBold
              value={attachment.sizeOnDisk}
              uiRenderType={"<NiceSize>"}
            />
          </CardContent>
          <CardActions>
            <Button
              onClick={() => addAttachmentHandler(attachment)} // Correct use of the prop
              variant="contained"
              color="primary"
              size="small"
            >
              {useButtonName}
            </Button>

            <Button
              component={Link}
              to={`/attachment/file/${attachment.id}`}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<DownloadOutlined />}
            >
              Download
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};
