import { Box } from "@mui/material";

const NiceMultiLine = (props) => {

  let multiLineString = props.children;

  return (
    // <Box
    // >
    //   {multiLineString.split("\n").map((line, index) => (
    //     <Box key={index}>{line}</Box>
    //   ))}
    // </Box>
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {multiLineString}
    </div>
  );
};

export default NiceMultiLine;
