import api from "./api";


const getAttachments = (search) => {

  return api.get("/attachment",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getAttachment = (id) => {
  return api.get("/attachment/"+id)
  .then((response) => {
    return response.data;
  });
};



const getAttachmentUi = () => {
  return api.get("/attachment/ui")
  .then((response) => {
    return response.data;
  });
};

const getAttachmentUsedByUi = () => {
  return api.get("/attachment/usedby/ui")
  .then((response) => {
    return response.data;
  });
};

const getAttachmentFilterValues = (search) => {

  return api.get("/attachment/filtervalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getAttachmentAggregateValues = (search) => {

  return api.get("/attachment/aggregatevalues",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const deleteAttachment = (id) => {
  return api.delete("/attachment/" + id).then((response) => {
    return response.data;
  });
};

const createAttachment = (attachment) => {
  
  return api.post("/attachment", {
    ...attachment,
  });
};

const getAttachmentCategories = () => {
  return api.get("/attachment_categories")
  .then((response) => {
    return response.data;
  });
};




const getAttachmentUsedBy = (search) => {
  return api.get("/attachments/usedby",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getAttachmentByUser = (search) => {
  return api.get("/attachments/byuser",{
    params: {
      ...search
    }
  })
  .then((response) => {
    return response.data;
  });
};

const AttachmentSevice = {
  getAttachment,
  getAttachmentUi,
  getAttachmentFilterValues,
  getAttachmentAggregateValues,
  getAttachments,
  deleteAttachment,
  createAttachment,
  getAttachmentCategories,
  getAttachmentByUser,
  getAttachmentUsedBy,
  getAttachmentUsedByUi,
};

export default AttachmentSevice;