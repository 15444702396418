import { Highlight, themes } from "prism-react-renderer";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import { Box, Typography } from "@mui/material";

import { useTheme } from "@emotion/react";
import {
  ErrorOutlineOutlined,
  LightbulbOutlined,
  Margin,
  WarningAmberOutlined,
} from "@mui/icons-material";


const transformOpsBloxSyntax = (markdownText) => {
  if (!markdownText) {
    return markdownText;
  }
  const lines = markdownText.split("\n");
  let inNoteBlock = false;
  let currentBlockType = "";
  const transformedLines = lines.map((line) => {
    // Check for line breaks within custom blocks
    if (inNoteBlock) {
      // Replace double spaces followed by a newline with <br />
      if (line.trim() === "") {
        inNoteBlock = false;
        return `</${currentBlockType}>\n`;
      }
      return line.trim() + "<br />\n";
    }
    if (line.startsWith(":note ")) {
      inNoteBlock = true;
      currentBlockType = "note";
      return `<note>${line.replace(":note ", "")}<br />\n`;
    } else if (line.startsWith(":warning ")) {
      inNoteBlock = true;
      currentBlockType = "warning";
      return `<warning>${line.replace(":warning ", "")}<br />\n`;
    } else if (line.startsWith(":tip ")) {
      inNoteBlock = true;
      currentBlockType = "tip";
      return `<tip>${line.replace(":tip ", "")}<br />\n`;
    } else {
      return `${line}\n`;
    }
  });
  if (inNoteBlock) {
    transformedLines.push(`</${currentBlockType}>`);
  }
  return transformedLines.join("");
};

const NiceMarkdown = (props) => {

  const FILE_URL = window.__RUNTIME_CONFIG__.REACT_APP_ATTACHMENT_URL;

  let message = transformOpsBloxSyntax(props.children);

  //transfor the file locations
  message = message?.replace(/\/attachment\/file\//g, FILE_URL);

  //debugger
  const theme = useTheme();

  // Function to parse the dimensions and other attributes from the title prop
  const parseImageAttributes = (title) => {
    const regex = /^\s*=\s*(\d+)(x(\d+))?(?:,([^,]+))?(?:,(\w+))?$/;
    const match = title.match(regex);
    const attributes = {};

    if (match) {
      attributes.width = match[1];
      attributes.height = match[3];
      attributes.newTitle = match[4];
      attributes.noshadow = match[5] === "false";
    } else {
      attributes.newTitle = title;
    }

    return attributes;
  };

  // Custom image component that applies the parsed dimensions and styles
  const CustomImage = ({ src, title, alt, ...props }) => {
    //debugger
    const { width, height, newTitle, noshadow } = parseImageAttributes(
      title || ""
    );
    const styles = {
      width: width ? `${width}px` : undefined,
      height: height ? `${height}px` : undefined,
      boxShadow: noshadow ? "none" : "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    };
    return (
      <img
        src={src}
        title={newTitle}
        alt={alt}
        width={width ? `${width}px` : undefined}
        height={height ? `${height}px` : undefined}
        style={styles}
        {...props}
      />
    );
  };

  const CustomNote = ({ children }) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={"1rem"}
        alignContent={"flex-start"}
        sx={{
          fontStyle: "italic",
          backgroundColor: theme.palette.markdownNote.main,
          marginBottom: "1rem",
          marginTop: "1rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: ".7rem",
          paddingBottom: ".7rem",
          borderRadius: "9px",
        }}
      >
        <ErrorOutlineOutlined />

        <div
          style={{
            //paddingTop: "1rem",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            whiteSpace: "pre-wrap",
          }}
        >
          {children}
        </div>
      </Box>
    );
  };

  const CustomWarning = ({ children }) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={"1rem"}
        alignContent={"flex-start"}
        sx={{
          //color: "white",
          fontStyle: "italic",
          backgroundColor: theme.palette.markdownWarning.main,
          marginBottom: "1rem",
          marginTop: "1rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: ".7rem",
          paddingBottom: ".7rem",
          borderRadius: "9px",
        }}
      >
        <WarningAmberOutlined/>

        <div
          style={{
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            whiteSpace: "pre-wrap",
          }}
        >
          {children}
        </div>
      </Box>
    );
  };

  const CustomTip = ({ children }) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={"1rem"}
        alignContent={"flex-start"}
        sx={{
          //color: "white",
          fontStyle: "italic",
          backgroundColor: theme.palette.markdownTip.main,
          marginBottom: "1rem",
          marginTop: "1rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: ".7rem",
          paddingBottom: ".7rem",
          borderRadius: "9px",
        }}
      >
        <LightbulbOutlined />

        <div
          style={{
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            whiteSpace: "pre-wrap",
          }}
        >
          {children}
        </div>
      </Box>
    );
  };

  return (
    <MuiMarkdown sx={{Margin:0}}
      options={{
        disableParsingRawHTML: false,

        overrides: {
          ...getOverrides({
            Highlight,
            themes,
            theme: themes.github,
            hideLineNumbers: true,
            styles: { whiteSpace: "pre-wrap" },
          }), // This will keep the other default overrides.
          // p: {
          //   component: 'p',
          //   props: {
          //     style: { color: 'red' },
          //   },
          // }, //this would set all paragraphs of type p to red

          note: {
            component: CustomNote,
          },
          warning: {
            component: CustomWarning,
          },
          tip: {
            component: CustomTip,
          },

          p: {
            component: "p",
            props: {
              style: {
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.fontSize,
                whiteSpace: "pre-wrap",
              },
            },
          },
          li: {
            component: "li",
            props: {
              style: {
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.fontSize,
                whiteSpace: "pre-wrap",
              },
            },
          },

          h1: ({ children }) => (
            <Typography variant="h2">{children}</Typography>
          ),
          h2: ({ children }) => (
            <Typography variant="h3">{children}</Typography>
          ),
          h3: ({ children }) => (
            <Typography variant="h4">{children}</Typography>
          ),
          h4: ({ children }) => (
            <Typography variant="h5">{children}</Typography>
          ),
          h5: ({ children }) => (
            <Typography variant="h6">{children}</Typography>
          ),
          img: { component: CustomImage },
          
          blockquote: {
            component: Box,
            props: {
              style: {
                borderLeft: "5px solid grey",
                paddingLeft: "10px",
                color: "grey",
                fontStyle: "italic",
                backgroundColor: "#f1f1f1",
              },
            },
          },
        },
      }}
    >
      {message}
    </MuiMarkdown>
  );
};

export default NiceMarkdown;
