import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
  Switch,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { setMessage, clearMessage } from "slices/message";
import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import FlexBetween from "components/global/FlexBetween";
import DetailBlock from "components/global/DetailBlock";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import { RefreshOutlined, StopOutlined } from "@mui/icons-material";

import TabPanel from "components/global/TabPanel";
import KeyVal from "components/global/KeyVal";
import { now } from "moment";
import TablePage from "components/global/TablePage";
import NiceUser from "components/global/NiceUser";

import ObjectDetails from "components/global/ObjectDetails";
import TabsVertical from "components/global/TabsVerticle";
import TabVertical from "components/global/TabVertical";
import { listAuditLog } from "slices/auditLog";
import { getService } from "slices/services";
import WhereUsedService from "components/where_used/WhereUsedService";


const Service = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const Service = useSelector((state) =>
    state.services.data.list?.find((element) => element.id === id)
  );

  const ServiceLog = useSelector((state) => state.cloudOrgStructure.logs);

  const auditLogRows = useSelector((state) => state.auditLog.data);

  //console.log("Log:", ServiceLog)

  const [tabValue, setTabValue] = React.useState(0);

  let refreshTimer = 20;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  useInterval(() => {}, 1000);

  useEffect(() => {
    if (!isFirstLoad) {
      //need to get the Service form the API
      dispatch(getService({ id }));
      setIsFirstLoad(false);
    }
  }, [id]);

  useEffect(() => {
    dispatch(getService({ id }));
  }, [refreshPoint]);

  const logColumns = [
    {
      field: "when",
      headerName: "Last Chnaged",
      flex: 1,
      renderCell: ({ row: { when } }) => {
        return <NiceDate>{when}</NiceDate>;
      },
    },
    {
      field: "username",
      headerName: "User",
      flex: 1,
      renderCell: ({ row: { username } }) => {
        return <NiceUser>{username}</NiceUser>;
      },
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 1,
    },
    {
      field: "fieldName",
      headerName: "Field",
      flex: 1,
    },
    {
      field: "fieldUpdate",
      headerName: "Change",
      flex: 1,
    },
    {
      field: "ipAddress",
      headerName: "From",
      flex: 1,
    },
  ];

  const columnsVisability = {};

  const [selectionModel, setSelectionModel] = React.useState([]);


  const [showHidden, setShowHidden] = React.useState(true);

  const toggleHidden = (event) => {
    setShowHidden(event.target.checked);
  };

  const [showTechnical, setShowTechnical] = React.useState(false);

  const toggleTechnical = (event) => {
    setShowTechnical(event.target.checked);
  };

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <FlexBetween>
        <Header title={Service?.name} subtitle={Service?.id} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>
        </FlexBetween>
      </FlexBetween>

      <Box mt="20px" sx={{ width: "100%" }}>
      <Box
          gap="1rem"
          sx={{
            //borderBottom: 1,
            borderTop: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            flexGrow: 1,
            
          }}
        >
          <TabsVertical
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Service Tabs"
          >
            <TabVertical label={"Details"} {...a11yProps(0)} />
            <TabVertical
              label={`Log (${auditLogRows?.total})`}
              {...a11yProps(2)}
            />
          </TabsVertical>

          <TabPanel value={tabValue} index={0}>
            {id != undefined ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                gap="1rem"
              >

                <ObjectDetails
                  serviceType={"services"}
                  id={id}
                  rowId={null}
                  refreshPoint={refreshPoint}
                  titleSetter={null}
                />

                
              </Box>
            ) : (
              <Typography>Nothing to load...</Typography>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            {ServiceLog ? (
              <TablePage
              masterId={id}
              masterIdField="objectId"
              dataRows={auditLogRows}
              dataColumns={logColumns}
              dataGetter={listAuditLog}
              defaultColumnsVisability={columnsVisability}
              defaultSort={"-when"}
              refreshPoint={refreshPoint}
              serviceType={"services"}
              initialFilter={"[operation]=Create,Update"}
            />
            ) : (
              <Typography>Empty Log...</Typography>
            )}
          </TabPanel>
        </Box>
      </Box>
    </PageBlock>
  );
};

export default Service;
