import { useDispatch } from "react-redux";
import React, { useRef, useState } from "react";

import {
  AttachFileOutlined,
  ErrorOutlineOutlined,
  HelpOutline,
  LightbulbOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";

import MDEditor from "@uiw/react-md-editor";
import "@uiw/react-md-editor/markdown-editor.css";

import { commands } from "@uiw/react-md-editor";

import NiceMarkdown from "components/global/NiceMarkdown";
import { createAttachement } from "slices/attachment";
import { Box, Popover } from "@mui/material";
import AttachmentPicker from "components/attachment/AttachmentPicker";

const CataloguePageEditor = ({
  page,
  handleSetPageUpdated,
  handleUpdateContent,
}) => {
  const dispatch = useDispatch();

  const [isProcessingImage, setIsProcessingImage] = useState(false);

  const [markdown, setMarkdown] = useState(page.content);

  console.log("editing: ", markdown);

  const handlePageChanged = (markdown) => {
    setMarkdown(markdown);
    handleSetPageUpdated(true);
    handleUpdateContent(markdown);
  };

  // useEffect(() => {
  //   setMarkdown(page?.content);
  // },[]);

  const handlePaste = async (event) => {
    if (!isProcessingImage) {
      setIsProcessingImage(true);

      const clipboardData = event.clipboardData;
      const items = clipboardData.items;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        // Check if the clipboard item is an image
        if (item.type.startsWith("image/")) {
          const blob = item.getAsFile();

          //debugger

          if (blob) {

            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64Image = reader.result.split(",")[1]; // Remove the data:image/png;base64, part

              // Prepare the newImage object with base64 encoded image
              const attachment = {
                content: base64Image,
                category: "General Image",
                description: "Image file uploaded",
              };

              // Perform the upload and handle the pasted blob
              dispatch(createAttachement({ attachment }))
                .unwrap()
                .then((imageReturn) => {
                  console.log("Got this back: ", imageReturn);

                  const newImageData = imageReturn.response.data;

                  if (imageReturn && imageReturn.response.status === 200) {
                    // Insert the uploaded image as Markdown
                    document.execCommand(
                      "insertText",
                      false,
                      `![${newImageData.name}](${newImageData.fileUrl} "${newImageData.description}")\n`
                    );
                  } else {
                    document.execCommand(
                      "insertText",
                      false,
                      "ERROR: Image has not been stored on the server"
                    );
                  }
                })
                .catch((error) => {
                  console.error("Error uploading image:", error);
                  document.execCommand(
                    "insertText",
                    false,
                    "ERROR: Image upload failed"
                  );
                })
                .finally(() => {
                  setIsProcessingImage(false); // Reset isProcessingImage after the asynchronous operations are finished
                });

              event.preventDefault();
            };

            //reader.readAsDataURL(blob); // Start reading the blob as data URL
          }
        }
      }
    }
  };

  const helpUrl = "/catalogue/663a19db1242002d9120d634";

  const [attachmentApi , setAttachmentApi] = useState(null)
  const [attachmentState , setAttachmentState] = useState(null)


  const [anchorEl, setAnchorEl] = useState(null); // For popover anchor
  const addRef = useRef(null);
  const editorRef = useRef(null);
  const areaHeight = "65vh";
  const areaWidth = "80vw";

  // Open the dialog when the custom command is executed
  const handleOpenAttachmentDialog = () => {
    setAnchorEl(addRef.current);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setAnchorEl(null);
  };

  const handleAddAttachment = (attachment) => {


    //debugger

    if (attachmentApi) {

      const attachmentLink = `/attachment/file/${attachment.id}`;
      let attachmentText = `[${attachment.name}](${attachmentLink} "${attachment.description}")`;

      if (attachment.category === "General Image"){
        attachmentText = "!" + attachmentText
      }

      attachmentApi.replaceSelection(attachmentText);
      attachmentApi.setSelectionRange({
        start: attachmentState.selection.start + attachmentText.length,
        end: attachmentState.selection.start + attachmentText.length,
      });

      handleCloseDialog();
    }
  };

  // Create a Attachment command
  const attachmentCommand = {
    name: "attach",
    keyCommand: "attach",
    buttonProps: {
      "aria-label": "Insert an attached file",
      title: "Insert Attachment",
    },
    icon: <AttachFileOutlined />, // Adjust size
    execute: (state, api) => {
      setAttachmentApi(api)
      setAttachmentState(state)
      handleOpenAttachmentDialog(); // Open the popover below the toolbar button
    },
  };

  // Create a custom help command
  const customHelpCommand = {
    name: "help",
    keyCommand: "help",
    buttonProps: { "aria-label": "Help", title: "How to use the editor" },
    icon: <HelpOutline />, // Adjust size
    execute: () => window.open(helpUrl, "_blank"),
  };

  const customNoteCommand = {
    name: "note",
    keyCommand: "note",
    buttonProps: { "aria-label": "Note", title: "Add a Note" },
    icon: <ErrorOutlineOutlined />, // Adjust size
    execute: (state, api) => {
      const noteText = ":note ";
      api.replaceSelection(noteText);
      api.setSelectionRange({
        start: state.selection.start + noteText.length,
        end: state.selection.start + noteText.length,
      });
    },
  };

  const customTipCommand = {
    name: "tip",
    keyCommand: "tip",
    buttonProps: { "aria-label": "Tip", title: "Add a Tip" },
    icon: <LightbulbOutlined />, // Adjust size
    execute: (state, api) => {
      const tipText = ":tip ";
      api.replaceSelection(tipText);
      api.setSelectionRange({
        start: state.selection.start + tipText.length,
        end: state.selection.start + tipText.length,
      });
    },
  };

  const customWarningCommand = {
    name: "warning",
    keyCommand: "warning",
    buttonProps: { "aria-label": "Warning", title: "Add a Warning" },
    icon: <WarningAmberOutlined />, // Adjust size
    execute: (state, api) => {
      const warningText = ":warning ";
      api.replaceSelection(warningText);
      api.setSelectionRange({
        start: state.selection.start + warningText.length,
        end: state.selection.start + warningText.length,
      });
    },
  };

  const defaultCommands = commands.getCommands().filter((cmd) => {
    if (cmd.name === "title") {
      // If the command is 'title', filter its children array
      cmd.children = cmd.children.filter((child) => child.name !== "title6");
    }
    return cmd.name !== "help";
  });

  // Merge the custom help command with the default commands
  let customToolbar = [...defaultCommands, customHelpCommand];

  //debugger

  // Find the index of the divider command
  const dividerIndex = customToolbar.findIndex((cmd) => cmd.name === "help");

  const customToolbarFinal = [
    ...customToolbar.slice(0, dividerIndex),
    customNoteCommand,
    customTipCommand,
    customWarningCommand,
    attachmentCommand,
    { keyCommand: "divider" },
    ...customToolbar.slice(dividerIndex),
  ];

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      height={"100%"}
      width={"100%"}
      sx={{
        ".w-md-editor-toolbar button svg": {
          width: 20,
          height: 20,
        },
      }}
    >
      <Box ref={addRef}></Box>

      <MDEditor
        ref={editorRef}
        commands={customToolbarFinal}
        value={markdown}
        onChange={handlePageChanged}
        height={"100%"}
        style={{ width: "100%" }}
        preview="live"
        textareaProps={{
          spellCheck: true,
          onPaste: handlePaste,
        }}
        components={{
          preview: (source) => {
            return <NiceMarkdown>{source}</NiceMarkdown>;
          },
        }}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseDialog}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <AttachmentPicker
            closerFunc={handleCloseDialog}
            useButtonName={"Use File"}
            addAttachmentHandler={handleAddAttachment}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default CataloguePageEditor;
