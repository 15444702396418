import {

  Box,
  ButtonBase,
  Stack,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";

import Typography from "@mui/material/Typography";

import { tokens } from "../../theme";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import { ArrowRightAltOutlined} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import GetMenuItems from "components/global/MenuItems";

import PropTypes from "prop-types";
import { cloneElement, useEffect, useState } from "react";
import {
  getAuditLogLastResource,
  getAuditLogLastService,
} from "slices/auditLog";
import { now } from "moment";
import { DataGrid } from "@mui/x-data-grid";
import NiceDate from "components/global/NiceDate";

import NiceService from "components/global/NiceService";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ServiceCard = ({service}) => {

  const theme = useTheme();
  const navigate = useNavigate();

  return(
    <ButtonBase 
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'flex-start',
        p: '.3rem',
        gap: '0.3rem',
        width: 120,
        height: 120,
        borderRadius: 2,
        boxShadow: 0,
        transition: 'box-shadow 0.3s ease-in-out', 
        '&:hover': {
          boxShadow: 6,
        },
        textDecoration: 'none', // Remove underline if used with a link
      }}
      // Add click event handler if needed
      onClick={() => { navigate(service.link) }}
    >
      <Box 
        sx={{
          width: 56,
          height: 56,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {cloneElement(service.icon, { sx: { fontSize: 56 , color: theme.palette.primary.main} })}
      </Box>

      <Typography align="center">{service.text}</Typography>
    </ButtonBase>


  )
};

const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const user = useSelector((state) => state.auth.user.user);

  const userName = user?.username;

  //const lastServices = useSelector((state) => state.auditLog.lastService);

  const lastResources = useSelector((state) => state.auditLog.lastResource);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuditLogLastService({ userName }));
    dispatch(getAuditLogLastResource({ userName }));
  }, [userName, dispatch]);

  const navAreas = GetMenuItems(user, "");

  //debugger;

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //debugger

  const recentColumns = [
    {
      field: "objectId",
      headerName: "Resource",
      flex: 2,
      renderCell: ({ row: { objectId, serviceType, implemenatorId } }) => {
        return (
          <NiceService wantedService={serviceType} implemenatorId={implemenatorId}>{objectId}</NiceService>
        );
      },
    },
    {
      field: "when",
      headerName: "Last Viewed",
      flex: .5,
      renderCell: ({ row: { when } }) => {
        return <NiceDate>{when}</NiceDate>;
      },
    },
  ];

  const [sortModel] = useState([
    {
      field: "when",
      sort: "desc",
    },
  ]);

  const itemMore = {
    text: "More",
    link: "service",
    icon: <ArrowRightAltOutlined />,
  }

  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      {/* <FlexBetween>
        <Header title={"Home"} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={""}>
            <RefreshOutlined />
          </Button>
        </FlexBetween>
      </FlexBetween> */}

      <Box display={"flex"} justifyContent={"center"} width={"100%"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"2rem"}
          maxWidth={"1200px"}
          minWidth={"1000px"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={".7rem"}>
            <Typography variant="h2">Services</Typography>

            <Stack direction={"row"} gap={"1rem"}>
            {navAreas.map((section, sectionIndex) => {
                // Combine all sub-arrays into one array
                const allItems = [
                    ...(section.mainItems || []),
                    ...(section.customItems || []),
                    ...(section.currentStatusItems || []),
                    ...(section.configItems || []),
                    ...(section.masterDataItems || [])
                ];

                // Filter items that have homePage set to true
                const homePageItems = allItems.filter(item => item.homePage);


                // Map over the filtered items to render ServiceCard components
                return homePageItems.map((item, itemIndex) => (
                    <ServiceCard key={`${sectionIndex}-${itemIndex}`} service={item} />
                ));
            })}

              <ServiceCard key={"more"} service={itemMore} />
            </Stack>
          </Box>

          <Box display={"flex"} flexDirection={"column"} gap={".7rem"}>
            <Typography variant="h2">Resources</Typography>

            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Resource Tabs"
            >
              <Tab label="Recent" {...a11yProps(0)} />
              <Tab label="Favorite" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              {lastResources?.list.length > 0 ? (
                <Box
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                      backgroundColor: "transparent",
                      height: "600px",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-row": {
                      //backgroundColor: theme.palette.background.light,
                      //backgroundColor: theme.palette.grey[10],
                      backgroundColor: "white",
                      borderBottomWidth: "1px",
                      borderBottomStyle: "solid",
                      borderBottomColor: theme.palette.grey[300],
                    },

                    "& .MuiDataGrid-columnHeaders": {
                      //backgroundColor: theme.palette.background.alt,
                      color: theme.palette.grey[900],
                      fontWeight: "bold",
                      borderBottomWidth: "2px",
                      borderBottomStyle: "solid",
                      borderBottomColor: theme.palette.grey[300],
                    },
                    // "& .MuiDataGrid-virtualScroller": {
                    //   backgroundColor: theme.palette.primary.light,
                    // },
                    "& .MuiDataGrid-footerContainer": {
                      //color: theme.palette.secondary[100],
                      borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                      //color: `${theme.palette.secondary[200]} !important`,
                    },
                  }}
                  display={"flex"}
                >
                  <DataGrid
                    //{...(rowId ? { getRowId: rowId } : {})}
                    //experimentalFeatures={{ newEditingApi: true }}

                    rows={lastResources?.list}
                    columns={recentColumns}
                    pageSize={12}
                    page={1}
                    paginationMode="server"
                    rowCount={12}
                    //autoHeight={true}
                    sortModel={sortModel}
                    hideFooter={true}
                    // components={{
                    //   Toolbar: CustomToolbar,
                    //   ColumnMenu: DataGridCustomColumnMenu,
                    // }}
                    headerHeight={45}
                    rowHeight={45}
                    //getRowHeight={() => "auto"}
                    //checkboxSelection
                    //disableSelectionOnClick
                    //keepNonExistentRowsSelected
                    //sortingMode="server"

                    // onSortModelChange={handleSortModelChange}
                    // columnVisibilityModel={columnVisibilityModel}
                    // onColumnVisibilityModelChange={(newModel) =>
                    //   setColumnVisibilityModel(newModel)
                    // }
                    // onSelectionModelChange={(newSelectionModel) => {
                    //   selectionSetter(newSelectionModel);
                    // }}
                    // selectionModel={selectionModel}
                    //footerRow={true} // Enable footer row
                  />
                </Box>
              ) : (
                <Typography>Loading</Typography>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              No Favorite
            </TabPanel>
          </Box>

          {/* <Box display={"flex"} flexDirection={"column"} gap={".7rem"}>
            <Typography variant="h2">Navigate</Typography>
            <Stack>test test</Stack>
          </Box> */}
        </Box>
      </Box>
    </PageBlock>
  );
};

export default Home;
