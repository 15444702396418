import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Search, Settings, SettingsOutlined } from "@mui/icons-material";
import {
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  Button,
  Divider,
  DialogTitle,
  DialogActions,
  Popover,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItem,
  ListItemText,
  Switch,
  List,
  Autocomplete,
  Chip,
  Popper,
  useTheme,
} from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridPagination,
  GridToolbarFilterButton,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  gridColumnDefinitionsSelector,
} from "@mui/x-data-grid";
import FlexBetween from "./global/FlexBetween";
import { Box } from "@mui/system";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import DataGridFilterCreate from "./DataGridFilterCreate";
import DataGridFilterDimension from "./DataGridFilterDimension";
import NiceDate from "./global/NiceDate";
import DataGridFilterBillingPeriod from "./DataGridFilterBillingPeriod";
import { getCurrentBillingPeriod } from "common/billingPeriod";
import { useDispatch, useSelector } from "react-redux";
import { getStateSetFilterValues } from "slices/state";
import NiceBillingPeriod from "./global/NiceBillingPeriod";

const createFilterObjectArray = (str) => {
  if (str.length > 1) {
  

    const filters = str.split("|");

    const arr = filters.map((filter) => {

      //debugger;

      //split the filter into the filed and the filter its self
      let key, value;
      const idx = filter.indexOf("=");
      key = filter.slice(0, idx);
      value = filter.slice(idx + 1);

      //remove the [] arround the filed
      const field = key.replace(/\[|\]/g, "");

      //look for the operator, if none is found then it must be the = used in the initial slit so add it back in
      let operatorSearch = value.match(/(<>|<=|<|>=|>|!=)/);
      let val, operator;

      if (operatorSearch === null) {
        operator = "=";
        val = value;
      } else {
        operator = operatorSearch[0];
        val = value.split(operator)[1];
      }

      return {
        key: field + "_" + operator + "_" + val,
        field: field,
        operator: operator,
        value: val,
      };
    });

    return arr;
  } else {
    return [];
  }
};

const DataGridFilter = ({
  columns,
  filters,
  filterSetter,
  kpi,
  dimension,
  dimensionSetter,
  masterId,
  masterIdField,
  stateSetId,
  serviceType,
  billingPeriod,
  billingPeriodSetter,
  billingRelevant,
  props,
}) => {

  const theme = useTheme();


  const [searchInput, setSearchInput] = useState("");
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [editFilter, setEditFilter] = React.useState("");
  const [editFilterColumn, setEditFilterColumn] = React.useState("");

  const [newFilters, setNewFilters] = React.useState(
    createFilterObjectArray(filters)
  );

  const dispatch = useDispatch();

  console.log("columns: ", columns);
  console.log("filters: ", filters);
  console.log("new Filters: ", newFilters);

  const filteredColumns = columns.filter((obj) => {

    const isExcludedField = newFilters.some((excludedObj) => excludedObj.field === obj.field );

    return (
      obj.type !== "actions" &&
      (obj.purpose === "" ||
        obj.purpose === "data" ||
        !obj.purpose ||
        obj.purpose === "key" ||
        obj.purpose === "measure") &&
        !isExcludedField &&
        obj.valueMode !== "Hidden"

    );
  });

  const handleDelete = (chipToDelete) => {
    const updatedFilters = newFilters.filter((chip) => {
      return (
        // chip.field !== chipToDelete.field ||
        // chip.operator !== chipToDelete.operator ||
        // chip.value !== chipToDelete.value

        chip.key !== chipToDelete.key
      );
    });

    updateFilterAPI(updatedFilters);
  };

  const searchRef = useRef(null);

  const updateFilterAPI = (filters) => {
    //debugger;

    let filterString = "";

    filters.forEach((filter) => {
      filterString += "[" + filter.field + "]";

      if (filter.operator != "=") {
        filterString += "=";
      }

      switch (filter.operator) {
        case "NN*":
          filterString += filter.value + "*|";
          break;
        case "*NN":
          filterString += "*" + filter.value + "|";
          break;
        case "*NN*":
          filterString += "*" + filter.value + "*|";
          break;
        case "null":
          filterString += "null" + "|";
          break;
        case "notnull":
          filterString += "!=null" + "|";
          break;
        default:
          //do nothing special
          filterString += filter.operator + filter.value + "|";
      }
    });

    //knock off the last '|' seperator
    filterString = filterString.slice(0, -1);

    //debugger;

    filterSetter(filterString);
  };

  var dimensions = [];

  if (kpi?.byMinute === true) {
    dimensions.push({ value: "Minute", label: "By Minute" });
  }

  if (kpi?.byHour === true) {
    dimensions.push({ value: "Hour", label: "By Hour" });
  }

  if (kpi?.byDay === true) {
    dimensions.push({ value: "Day", label: "By Day" });
  }

  if (kpi?.byWeek === true) {
    dimensions.push({ value: "Week", label: "By Week" });
  }

  if (kpi?.byMonth === true) {
    dimensions.push({ value: "Month", label: "By Month" });
  }

  if (kpi?.byQuarter === true) {
    dimensions.push({ value: "Quarter", label: "By Quarter" });
  }

  if (kpi?.byYear === true) {
    dimensions.push({ value: "Year", label: "By year" });
  }

  const addFilter = (newFilter) => {
    //create a filter URL and pass back to the parent, this will update the screen which will in turn flow back here to
    //create the filters needed for display after the API has been consumed

    //debugger;

    if (newFilter != null) {
      let activeFilters = [...newFilters];

      // Check if we need to amend or add
      const indexToUpdate = activeFilters.findIndex(
        (obj) => obj.key === newFilter.oldKey
      );

      if (indexToUpdate !== -1) {
        // Replace the existing filter with newFilter
        activeFilters[indexToUpdate] = newFilter;
      } else {
        // Add newFilter to the array if it doesn't exist
        activeFilters.push(newFilter);
      }

      updateFilterAPI(activeFilters);

      setValue("");
      setInputValue("");
    }
  };

  const clearFilters = () => {
    if (kpi) {
      // Remove any non-dimension filters that are set
      const filteredFilters = newFilters.filter((filter) => {
        const column = columns.find((obj) => obj.field === filter.field);
        return column && column.purpose === "dimension";
      });

      //debugger;

      updateFilterAPI(filteredFilters);
    } else {
      filterSetter([]);
    }
  };

  const [anchorElFilterSettings, setAnchorElFilterSettings] = useState(null);

  const isOpenFilterSettings = Boolean(anchorElFilterSettings);

  const [anchorElDimensionSettings, setAnchorElDimensionSettings] =
    useState(null);

  const isOpenDimensionSettings = Boolean(anchorElDimensionSettings);

  const handleCloseDimensionSettings = () => {
    setAnchorElDimensionSettings(null);
  };

  const [anchorElBillingPeriodSettings, setAnchorElBillingPeriodSettings] =
    useState(null);

  const isOpenBillingPeriodSettings = Boolean(anchorElBillingPeriodSettings);

  const handleCloseBillingPeriodSettings = () => {
    setAnchorElBillingPeriodSettings(null);
  };

  const handleCloseFilterSettings = () => {
    setAnchorElFilterSettings(null);
    setValue("");
    setInputValue("");
    setEditFilter("");
    setEditFilterColumn("");
  };

  const handleOpenFilterSettngs = (event, newValue) => {
    if (newValue != null) {
      setAnchorElFilterSettings(searchRef.current);
      setEditFilter("");
      setEditFilterColumn("");
    }
  };

  const handelOpenEditFilter = (event, column, filter) => {
    setAnchorElFilterSettings(event.currentTarget);
    setEditFilter(filter);
    setEditFilterColumn(column);
  };

  const handelOpenDimensionPicker = (event) => {
    setAnchorElDimensionSettings(event.currentTarget);
  };

  const handelOpenBillingPeriodsPicker = (event) => {
    setAnchorElBillingPeriodSettings(event.currentTarget);
  };


  const [billingPeriods, setBillingPeriods] = useState([]);

  const [isLoadingBillingPeriods, setIsLoadingBillingPeriods] = useState(true);

  const avilablePeriods = useSelector(
    (state) => state.stateSet?.filter_values?.stringValues
  );

  useEffect(() => {

    if (!avilablePeriods){
    const search = {
      id: "",
      idField: "",
      field: "billingPeriod",
      filter: "",
      dimension: "",
      stateSetId: "CUSTOMER_BILLING_PERIODS",
      billingPeriod: "",
      sort: "billingPeriod",
    };

    console.log("Get Billing Periods", search);

    dispatch(getStateSetFilterValues({ search })).then(() => {
      setIsLoadingBillingPeriods(false); // Mark loading as complete after data is fetched
      setBillingPeriods(avilablePeriods);
    });
  }

  }, []);

  // useEffect(() => {
  //   if (!isLoadingBillingPeriods) {
  //     setBillingPeriods(avilablePeriods);
  //   }
  // }, [isLoadingBillingPeriods]);


  return (
    <>
      {columns?.length > 0 && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Autocomplete
            ref={searchRef}
            freeSolo
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              handleOpenFilterSettngs(event, newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="search"
            //options={columns.filter((obj) => obj.type !== "actions" && obj.purpose !=="dimension" && obj.purpose === "")}
            options={filteredColumns}
            getOptionLabel={(option) => (option ? option.headerName : "")}
            sx={{ mb: "0.4rem", width: "20rem" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                variant="standard"
                size="small"
              />
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.headerName, inputValue, {
                insideWords: true,
              });
              const parts = parse(option.headerName, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />

          {(newFilters?.length > 0 || billingRelevant) && (
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Box
                component="ul"
                display="flex"
                justifyContent="flex-start"
                p="0rem"
                height="1rem"
              >
                {billingRelevant && avilablePeriods &&
                  (() => {
                    // Perform a lookup to find the correct billing period based on the 'billingPeriod' variable
                    const thisBillingPeriod = avilablePeriods.find(
                      (obj) => obj === billingPeriod
                    );

                    // Check if 'thisBillingPeriod' is found before rendering
                    if (thisBillingPeriod) {
                      return (
                        <ListItem
                          key="billing period_key"
                          sx={{ padding: "0px 1rem 0px 0px" }}
                        >
                          <Chip
                          color={"secondary"}
                            onClick={(event) =>
                              handelOpenBillingPeriodsPicker(event)
                            }
                            size="small"
                            label={
                              <>
                                Billing Period{" "}
                                <Typography
                                  component="span"
                                  style={{
                                    fontFamily: "inherit",
                                    fontSize: "inherit",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <NiceBillingPeriod>{thisBillingPeriod}</NiceBillingPeriod>
                                </Typography>
                              </>
                            }
                            sx={{
                              borderRadius: "5px",
                            }}
                          />
                        </ListItem>
                      );
                    } else {
                      // Handle the case when 'thisBillingPeriod' is not found
                      return <Box>Loading...</Box>; // or render some fallback content
                    }
                  })()}

                {kpi &&
                  (() => {
                    // Perform a lookup to find the correct dimension based on the 'dimension' variable
                    const thisDimension = dimensions.find(
                      (obj) => obj.value === dimension
                    );

                    // Check if 'thisDimension' is found before rendering
                    if (thisDimension) {
                      return (
                        <ListItem
                          key="dimension_time_key"
                          sx={{ padding: "0px 1rem 0px 0px" }}
                        >
                          <Chip
                          color={"secondary"}
                            onClick={(event) =>
                              handelOpenDimensionPicker(event)
                            }
                            size="small"
                            label={
                              <>
                                View{" "}
                                <Typography
                                  component="span"
                                  style={{
                                    fontFamily: "inherit",
                                    fontSize: "inherit",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {thisDimension.label}
                                </Typography>
                              </>
                            }
                            sx={{
                              borderRadius: "5px",
                            }}
                          />
                        </ListItem>
                      );
                    } else {
                      // Handle the case when 'thisDimension' is not found
                      return null; // or render some fallback content
                    }
                  })()}

                {newFilters?.map((filter) => {
                  const column = columns.find(
                    (obj) => obj.field === filter.field
                  );

                  return (
                    <ListItem
                      key={filter.key}
                      sx={{ padding: "0px 1rem 0px 0px"}}
                    >
                      <Chip
                        color={"secondary"}

                        onClick={(event) => {
                          handelOpenEditFilter(event, column, filter);
                        }}
                        size="small"
                        label={
                          <>
                            {column.headerName}{" "}
                            <Typography
                              component="span"
                              style={{
                                fontFamily: "inherit",
                                fontSize: "inherit",
                                fontWeight: "bold",
                              }}
                            >
                              {/* {filter.operator} {column.type === "date" ? filter.value === "all" ? "all" : <NiceDate>{filter.value}</NiceDate> : filter.value} */}
                              {filter.operator === "<>" ? "From" : filter.operator}{" "}
                              {(() => {
                                const values = filter.value.split(",");
                                if (values.length > 1) {

                                  //check if this is a range or an array
                                  if(filter.operator === "<>"){
                                  
                                    if (column.type === "date") {
                                      
                                      return (<><NiceDate>{values[0]}</NiceDate> To <NiceDate>{values[1]}</NiceDate></>) // Display the range
                                    } else {
                                      return `${values[0]} to ${values[1]}`; // Display the range
                                    }

                                    
                                    
                                  }
                                  return `${values.length} values selected`; // Display the number of elements in the array

                                } else if (filter.value === "all") {
                                  return "all"; // Display "all" if filter.value is "all"
                                } else if (column.type === "date") {
                                  return <NiceDate>{filter.value}</NiceDate>; // Display the formatted date
                                } else {
                                  return filter.value; // Display the value as is
                                }
                              })()}
                            </Typography>
                          </>
                        }
                        onDelete={
                          column.purpose !== "dimension"
                            ? () => {
                                handleDelete(filter);
                              }
                            : null
                        }
                        sx={{
                          borderRadius: "5px",
                        }}
                      />
                    </ListItem>
                  );
                })}
              </Box>

              <Divider orientation="vertical" flexItem variant="middle" />

              <Button
                size="small"
                variant="outlined"
                color="primary"
                sx={{ ml: "1rem" }}
                onClick={clearFilters}
              >
                Clear Filters
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Popover
        anchorEl={anchorElFilterSettings}
        id="filter-settings"
        open={isOpenFilterSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ translate: "0rem 0.5rem" }}
      >
        <DataGridFilterCreate
          column={editFilterColumn !== "" ? editFilterColumn : value}
          closer={handleCloseFilterSettings}
          saver={addFilter}
          currentFilterValues={editFilter}
          filters={filters}
          kpi={kpi}
          dimension={dimension}
          masterIdField={masterIdField}
          masterId={masterId}
          serviceType={serviceType}
          stateSetId={stateSetId}
          billingPeriod={billingPeriod}
        />
      </Popover>

      <Popover
        anchorEl={anchorElDimensionSettings}
        id="dimension-settings"
        open={isOpenDimensionSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ translate: "0rem 0.5rem" }}
      >
        <DataGridFilterDimension
          closer={handleCloseDimensionSettings}
          saver={dimensionSetter}
          currentDimension={dimension}
          dimensions={dimensions}
        />
      </Popover>

      <Popover
        anchorEl={anchorElBillingPeriodSettings}
        id="billingPeriod-settings"
        open={isOpenBillingPeriodSettings}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ translate: "0rem 0.5rem" }}
      >
        <DataGridFilterBillingPeriod
          closer={handleCloseBillingPeriodSettings}
          saver={billingPeriodSetter}
          currentBillingPeriod={billingPeriod}
          billingPeriods={avilablePeriods}
        />
      </Popover>
    </>
  );
};

export default DataGridFilter;
