import {
  Typography,
  useTheme,
  Button,
  Grid,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Avatar,
  CardActions,
} from "@mui/material";

import { catalogueTypes } from "common/catalogueDefaults";

import { RefreshOutlined, MoreVertOutlined } from "@mui/icons-material";

import { tokens } from "theme";
import Header from "components/global/Header";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";

import { now } from "moment";
import { link } from "@uiw/react-md-editor";
import { getCatalogues } from "slices/catalogue";
import KeyVal from "components/global/KeyVal";

const CatalogueTypeList = ({ catalogueType, catalogues }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const catalogusByType = catalogues.list.filter(
    (catalogue) => catalogue.type === catalogueType && catalogue.active === true
  );

  let icon = catalogueTypes.get(catalogueType);

  //console.log("cataloguys by type: ", catalogueType, " ", catalogusByType);

  const handleOpenCatalogue = (id) => {
    navigate("/catalogue/" + id);
  };

  return (
    <>
      {catalogusByType.map((catalogue) => (
        <Grid item xs={12} sm={6} md={3} key={catalogue.id}>
          <Card key={catalogue.id} sx={{ maxWidth: 370 }} variant="outlined">
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: "primary.main", width: 56, height: 56 }}>
                  {icon}
                </Avatar>
              }
              action={
                <IconButton aria-label="settings">
                  <MoreVertOutlined />
                </IconButton>
              }
              title={catalogue.name}
              subheader={catalogue.type}
              titleTypographyProps={{ variant: "h2", color: "primary" }}
              subheaderTypographyProps={{ variant: "h4" }}
              sx={{ alignItems: "flex-start" }} // Align items to the top
            />

            <CardContent>
              <KeyVal label={"Cloud"} labelBold value={catalogue.cloud}/>
              <KeyVal label={"Pages in Catalogue"} labelBold value={catalogue.pages}/>

              <Typography>{catalogue.description}</Typography>
            </CardContent>
            <CardActions>
              <Button
                component={Link}
                to={`/catalogue/${catalogue.id}`}
                variant="contained"
                color="primary"
              >
                Open
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </>
  );
};

const CatalogueIndex = () => {

  const dispatch = useDispatch();

  const catalogues = useSelector((state) => state.catalogue.catalogue.data);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  useEffect(() => {
    const search = {
      filter: "[active]=true",
      sort: "sequence",
    };
    dispatch(getCatalogues({ search }));
  }, [refreshPoint, dispatch]);

  // const handleOpen = () => {
  //   navigate("/catalogue/" + selectionModel[0]);
  // };

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs />
        <FlexBetween>
          <Header title="Catalogues" subtitle="" count={catalogues.total} />
          <FlexBetween gap=".5rem">
            <Button variant="outlined" color="primary" onClick={handelRefresh}>
              <RefreshOutlined />
            </Button>
          </FlexBetween>
        </FlexBetween>

        {catalogues.total > 0 ? (
          <Grid container spacing={2} mt={"1rem"}>
            {Array.from(catalogueTypes).map(([key, value]) => (
              <CatalogueTypeList
                key={key}
                catalogueType={key}
                catalogues={catalogues}
              />
            ))}
          </Grid>
        ) : (
          <Typography>Loading</Typography>
        )}
      </PageBlock>
    </>
  );
};

export default CatalogueIndex;
