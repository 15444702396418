import {
  Box,
  Typography,
  useTheme,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
  Switch,
} from "@mui/material";
import { tokens } from "theme";
import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getOpportunity,
  getOpportunityNotes,
  updateOpportunity,
} from "slices/opportunity";
import { setMessage, clearMessage } from "slices/message";
import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";
import NiceDuration from "components/global/NiceDuration";
import FlexBetween from "components/global/FlexBetween";
import DetailBlock from "components/global/DetailBlock";
import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import {
  CheckOutlined,
  CloseOutlined,
  RefreshOutlined,
  StopOutlined,
} from "@mui/icons-material";

import TabPanel from "components/global/TabPanel";
import KeyVal from "components/global/KeyVal";
import { now } from "moment";
import TablePage from "components/global/TablePage";
import NiceUser from "components/global/NiceUser";
import ObjectDetails from "components/global/ObjectDetails";
import { listAuditLog } from "slices/auditLog";
import TabVertical from "components/global/TabVertical";
import TabsVertical from "components/global/TabsVerticle";

const Opportunity = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const Opportunity = useSelector((state) =>
    state.opportunity.data.list?.find((opportunity) => opportunity.id === id)
  );

  const OpportunityNotes = useSelector((state) => state.opportunity.notes);
  const auditLogRows = useSelector((state) => state.auditLog.data);

  //console.log("Log:", OpportunityLog)

  const [tabValue, setTabValue] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();


  const setTabValueAndUpdateQuery = (newValue) => {
    // Update the state
    setTabValue(newValue);

    // Update the query string
    const queryParams = new URLSearchParams(searchParams.toString());
    queryParams.set("tab", newValue);
    setSearchParams(queryParams.toString(), { replace: true });

    //console.log("setting tab:", queryParams.toString());
  };

  const handleTabChange = (event, newValue) => {
    setTabValueAndUpdateQuery(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const handleValidate = () => {
    dispatch(clearMessage());
    const opportunity = { id: id, stage: "To Be Prevented" };
    dispatch(updateOpportunity({ opportunity }));
  };

  const handleReject = () => {
    dispatch(clearMessage());
    const opportunity = { id: id, stage: "Rejected" };
    dispatch(updateOpportunity({ opportunity }));
  };

  useInterval(() => {}, 1000);

  useEffect(() => {
    if (!isFirstLoad) {
      //need to get the Opportunity form the API
      dispatch(getOpportunity({ id }));
      setIsFirstLoad(false);
    }
  }, [id]);

  useEffect(() => {
    dispatch(getOpportunity({ id }));
  }, [refreshPoint]);

  const notesColumns = [
    {
      field: "modifiedAt",
      headerName: "Last Chnaged",
      flex: 1,
      renderCell: ({ row: { modifiedAt } }) => {
        return <NiceDate>{modifiedAt}</NiceDate>;
      },
    },
    {
      field: "name",
      headerName: "Title",
      flex: 1,
    },

    {
      field: "note",
      headerName: "Note",
      flex: 6,
    },
  ];

  const logColumns = [
    {
      field: "when",
      headerName: "Last Chnaged",
      flex: 1,
      renderCell: ({ row: { when } }) => {
        return <NiceDate>{when}</NiceDate>;
      },
    },
    {
      field: "username",
      headerName: "User",
      flex: 1,
      renderCell: ({ row: { username } }) => {
        return <NiceUser>{username}</NiceUser>;
      },
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 1,
    },
    {
      field: "fieldName",
      headerName: "Field",
      flex: 1,
    },
    {
      field: "fieldUpdate",
      headerName: "Change",
      flex: 1,
    },
    {
      field: "ipAddress",
      headerName: "From",
      flex: 1,
    },
  ];

  const columnsVisability = {};

  const [selectionModel, setSelectionModel] = React.useState([]);



  return (
    <PageBlock>
      <SimpleBreadcrumbs />

      <FlexBetween>
        <Header title={Opportunity?.id} subtitle={Opportunity?.stage} />
        <FlexBetween gap=".5rem">
          <Button variant="outlined" color="primary" onClick={handelRefresh}>
            <RefreshOutlined />
          </Button>

          {Opportunity?.stage === "To Be Validated" && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CheckOutlined />}
              onClick={handleValidate}
            >
              Validate
            </Button>
          )}

          {Opportunity?.stage === "To Be Validated" && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CloseOutlined />}
              onClick={handleReject}
            >
              Reject
            </Button>
          )}
        </FlexBetween>
      </FlexBetween>

      <Box mt="20px" sx={{ width: "100%" }}>
        <Box
          gap="1rem"
          sx={{
            //borderBottom: 1,
            borderTop: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            flexGrow: 1,
          }}
        >
          <TabsVertical
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Opportunity Tabs"
          >
            <TabVertical label={"Details"} {...a11yProps(0)} />
            <TabVertical
              label={`Notes (${OpportunityNotes?.total})`}
              {...a11yProps(1)}
            />
            <TabVertical label={`Attachments`} {...a11yProps(1)} />
            <TabVertical
              label={`Change Log (${auditLogRows?.total})`}
              {...a11yProps(2)}
            />
          </TabsVertical>

          <TabPanel value={tabValue} index={0}>
            {id !== undefined ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                gap="1rem"
              >

                <ObjectDetails
                  serviceType={"opportunity"}
                  id={id}
                  rowId={null}
                  refreshPoint={refreshPoint}
                  titleSetter={null}
                  hideId={false}
                />
              </Box>
            ) : (
              <Typography>Nothing to load...</Typography>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <TablePage
              masterId={id}
              masterIdField="opportunityId"
              dataRows={OpportunityNotes}
              dataColumns={notesColumns}
              dataGetter={getOpportunityNotes}
              defaultColumnsVisability={columnsVisability}
              defaultSort={"-modifiedAt"}
              refreshPoint={refreshPoint}
              serviceType={"OpportunityNote"}
              tab
            />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            Attahcmnets
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <TablePage
              masterId={id}
              masterIdField="objectId"
              dataRows={auditLogRows}
              dataColumns={logColumns}
              dataGetter={listAuditLog}
              defaultColumnsVisability={columnsVisability}
              defaultSort={"-when"}
              refreshPoint={refreshPoint}
              serviceType={"auditLog"}
              initialFilter={"[operation]=Create,Update"}
            />
          </TabPanel>
        </Box>
      </Box>
    </PageBlock>
  );
};

export default Opportunity;
