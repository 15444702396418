import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route, useParams, Navigate } from "react-router-dom";

import Dashboards from "./scenes/dashboard";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useDispatch, useSelector } from "react-redux";

import { PrivateRoute } from "./components/global/PrivateRoute";

import Login from "./components/user/Login";
import { logout, updatePreferenceByUser } from "./slices/auth";

import Layout from "./scenes/global/layout";
import Forbidden from "./scenes/global/forbidden";
import NotFound from "./scenes/global/notFound";
import Privacy from "./scenes/global/privacy";
import Terms from "./scenes/global/terms";
import Contact from "./scenes/global/contact";
import About from "./scenes/global/about";
import Pricing from "./scenes/global/pricing";
import Leadership from "./scenes/global/leadership";
import Demo from "scenes/global/demo";

import Bloxs from "scenes/blox";

import UserRoles from "./scenes/admin/user_roles";
import Orchestrators from "scenes/orchestrator";

import Home from "./scenes/home";

import FAQ from "./scenes/faq";

import Profile from "./scenes/user/profile";
import UserList from "./scenes/user/list";
import UserDetail from "./scenes/user/detail";

import Register from "./scenes/user/register";

import Product from "scenes/global/product";

import Organization from "scenes/organization/organization";
import Orchestrator from "scenes/orchestrator/orchestrator";
import Blox from "scenes/blox/blox";
import Utility from "scenes/utility/utility";
import Utilities from "scenes/utility";

import Kpi from "scenes/kpi/kpi";
import Kpis from "scenes/kpi";

import Jobs from "scenes/job";
import Job from "scenes/job/job";

import Schedules from "scenes/schedule";
import Schedule from "scenes/schedule/schedule";

import WorkflowContribution from "scenes/workflow_contribution/workflow_contribution";
import WorkflowContributions from "scenes/workflow_contribution";
import Workflows from "scenes/workflow";
import Workflow from "scenes/workflow/workflow";
import WorkflowSteps from "scenes/workflow_step";
import WorkflowStep from "scenes/workflow_step/workflow_step";
import StateSet from "scenes/state/state";
import StateSets from "scenes/state";
import UserSessions from "scenes/admin/user_session";
import UserSession from "./scenes/admin/user_session/session";
import UserRole from "scenes/admin/user_roles/user_role";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/en-gb";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import moment from "moment-timezone";
import TablePageRowDetail from "scenes/global/TablePageRowDetail";
import Services from "scenes/services";
import Service from "scenes/services/service";
import Dashboard from "scenes/dashboard/dashboard";
import Opportunity from "scenes/opportunity/opportunity";
import Opportunities from "scenes/opportunity";
import CloudOrgStructure from "scenes/cloud_org_structure/cloud_org_structure";
import CloudOrgStructures from "scenes/cloud_org_structure";
import AuditLogs from "scenes/audit_log";
import Preferences from "scenes/preferences";
import Sequence from "scenes/sequence";
import CopilotConversations from "scenes/copilot/indexConversations";
import CopilotPrompts from "scenes/copilot/indexPrompts";
import { clearMessage } from "slices/message";
import Catalogue from "scenes/catalogue/catalogue";

import AdminCataloguePage from "scenes/catalogue/adminCataloguePage";
import AdminCatalogueIndex from "scenes/catalogue/adminIndexCatalogue";
import AdminCatalogue from "scenes/catalogue/adminCatalogue";
import AdminCataloguePageIndex from "scenes/catalogue/adminIndexCataloguePage";
import SearchPrompts from "scenes/search/indexSearchPromps";
import CatalogueIndex from "scenes/catalogue/indexCatalogue";
import Status from "scenes/global/status";
import Backup from "scenes/backup";
import Attachment from "scenes/attachment/attachment";
import Attachments from "scenes/attachment";

function RedirectToNewPath() {
  const { id } = useParams();
  const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_ATTACHMENT_URL;

  useEffect(() => {
    const newUrl = `${API_URL}${id}`;
    console.log("Redirecting to:", newUrl);
    window.location.href = newUrl; // Redirect using window.location.href
  }, [id, API_URL]);

  return null; // Return nothing since we are redirecting
}

function App() {
  const [theme, colorMode] = useMode();

  const { isLoggedIn } = useSelector((state) => state.auth);

  const [isNavMenuOpen, setIsNavMenuOpen] = useState(true);

  const initialCopilotMode = useSelector((state) => {
    const preference = state.auth.user.preferences?.find(
      (preference) => preference.name === "copilotOpen"
    )?.preferenceValue;

    return preference ? (preference === "true" ? true : false) : false;
  });

  const initialCopilotSize = useSelector((state) => {
    const preference = state.auth.user.preferences?.find(
      (preference) => preference.name === "copilotSize"
    )?.preferenceValue;

    return preference ? preference : "25";
  });

  const [isCopilotOpen, setIsCopilotOpen] = useState(initialCopilotMode);
  const [copilotSize, setCopilotSize] = useState(initialCopilotSize);

  const [menuArea, setMenuArea] = useState("HOME");

  const { user: currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const locales = ["en-us", "en-gb", "zh-cn", "de"];

  const [locale, setLocale] = React.useState("en-gb");

  if (moment.locale() !== locale) {
    moment.locale(locale);
  }

  const handelSetCopilotMode = (mode) => {
    //now update the user preference

    dispatch(clearMessage());

    const preference = {
      preference_value: mode ? "true" : "false",
      name: "copilotOpen",
      username: currentUser.user.username,
    };

    dispatch(updatePreferenceByUser({ preference }));

    setIsCopilotOpen(mode);
  };

  const handelSetCopilotSize = (size) => {
    //now update the user preference

    dispatch(clearMessage());

    const preference = {
      preference_value: size,
      name: "copilotSize",
      username: currentUser.user.username,
    };

    dispatch(updatePreferenceByUser({ preference }));

    setCopilotSize(size);
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      dateLibInstance={moment}
      adapterLocale={locale}
    >
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Routes>
            <Route
              element={
                <Layout
                  copilotSize={copilotSize}
                  setCopilotSize={handelSetCopilotSize}
                  setIsCopilotOpen={handelSetCopilotMode}
                  isCopilotOpen={isCopilotOpen}
                  setIsNavMenuOpen={setIsNavMenuOpen}
                  isNavMenuOpen={isNavMenuOpen}
                  menuArea={menuArea}
                />
              }
            >
              <Route path="/" element={<Home />} />

              <Route
                path="/attachment/file/:id"
                element={<RedirectToNewPath />}
              />

              <Route
                path="/organization/:id"
                element={
                  <PrivateRoute>
                    <Organization />
                  </PrivateRoute>
                }
              />
              <Route
                path="/orchestrator/:id"
                element={
                  <PrivateRoute>
                    <Orchestrator />
                  </PrivateRoute>
                }
              />

              <Route
                path="/orchestrator"
                element={
                  <PrivateRoute>
                    <Orchestrators />
                  </PrivateRoute>
                }
              />
              <Route
                path="/blox"
                element={
                  <PrivateRoute>
                    <Bloxs />
                  </PrivateRoute>
                }
              />
              <Route
                path="/blox/:id"
                element={
                  <PrivateRoute>
                    <Blox />
                  </PrivateRoute>
                }
              />

              <Route
                path="/service"
                element={
                  <PrivateRoute>
                    <Services />
                  </PrivateRoute>
                }
              />
              <Route
                path="/service/:id"
                element={
                  <PrivateRoute>
                    <Service />
                  </PrivateRoute>
                }
              />

              <Route
                path="/utility"
                element={
                  <PrivateRoute>
                    <Utilities />
                  </PrivateRoute>
                }
              />
              <Route
                path="/utility/:id"
                element={
                  <PrivateRoute>
                    <Utility />
                  </PrivateRoute>
                }
              />

              <Route
                path="/config"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"config"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/copilot"
                element={
                  <PrivateRoute>
                    <CopilotConversations />
                  </PrivateRoute>
                }
              />

              <Route
                path="/copilot/:id"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"copilotConversation"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/copilot/prompt"
                element={
                  <PrivateRoute>
                    <CopilotPrompts />
                  </PrivateRoute>
                }
              />

              <Route
                path="/copilot/prompt/:id"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"copilotPrompt"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/search_prompt"
                element={
                  <PrivateRoute>
                    <SearchPrompts />
                  </PrivateRoute>
                }
              />

              <Route
                path="/search_prompt/:id"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"searchPrompt"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/catalogue"
                element={
                  <PrivateRoute>
                    <CatalogueIndex />
                  </PrivateRoute>
                }
              />

              <Route
                path="/catalogue/:id"
                element={
                  <PrivateRoute>
                    <Catalogue />
                  </PrivateRoute>
                }
              />
              <Route
                path="/catalogue/:id/:pageId"
                element={
                  <PrivateRoute>
                    <Catalogue />
                  </PrivateRoute>
                }
              />

              <Route
                path="/catalogue_admin"
                element={
                  <PrivateRoute>
                    <AdminCatalogueIndex />
                  </PrivateRoute>
                }
              />

              <Route
                path="/catalogue_admin/:id"
                element={
                  <PrivateRoute>
                    <AdminCatalogue />
                  </PrivateRoute>
                }
              />

              <Route
                path="/catalogue_page_admin"
                element={
                  <PrivateRoute>
                    <AdminCataloguePageIndex />
                  </PrivateRoute>
                }
              />

              <Route
                path="/catalogue_page_admin/:id"
                element={
                  <PrivateRoute>
                    <AdminCataloguePage />
                  </PrivateRoute>
                }
              />

              <Route
                //path="/utility/:id/:rowId"
                path="/utility/:id/:rowId"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"utility"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/kpi"
                element={
                  <PrivateRoute>
                    <Kpis />
                  </PrivateRoute>
                }
              />
              <Route
                path="/kpi/:id"
                element={
                  <PrivateRoute>
                    <Kpi />
                  </PrivateRoute>
                }
              />

              <Route
                path="/kpi/:id/:rowId"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"kpi"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/workflow"
                element={
                  <PrivateRoute>
                    <Workflows />
                  </PrivateRoute>
                }
              />
              <Route
                path="/workflow/:id"
                element={
                  <PrivateRoute>
                    <Workflow />
                  </PrivateRoute>
                }
              />

              <Route
                path="/workflowcontribution"
                element={
                  <PrivateRoute>
                    <WorkflowContributions />
                  </PrivateRoute>
                }
              />
              <Route
                path="/workflowcontribution/:id"
                element={
                  <PrivateRoute>
                    <WorkflowContribution />
                  </PrivateRoute>
                }
              />

              <Route
                path="/workflowstep"
                element={
                  <PrivateRoute>
                    <WorkflowSteps />
                  </PrivateRoute>
                }
              />
              <Route
                path="/workflowstep/:id"
                element={
                  <PrivateRoute>
                    <WorkflowStep />
                  </PrivateRoute>
                }
              />

              <Route
                path="/backup"
                element={
                  <PrivateRoute>
                    <Backup />
                  </PrivateRoute>
                }
              />

              <Route
                path="/backup/:id"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"backup"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/attachment"
                element={
                  <PrivateRoute>
                    <Attachments />
                  </PrivateRoute>
                }
              />

              <Route
                path="/attachment/:id"
                element={
                  <PrivateRoute>
                    <Attachment/>
                  </PrivateRoute>
                }
              />

              <Route
                path="/sequence"
                element={
                  <PrivateRoute>
                    <Sequence />
                  </PrivateRoute>
                }
              />

              <Route
                path="/sequence/:id"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"sequence"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/opportunity"
                element={
                  <PrivateRoute>
                    <Opportunities />
                  </PrivateRoute>
                }
              />
              <Route
                path="/opportunity/:id"
                element={
                  <PrivateRoute>
                    <Opportunity />
                    {/* <TablePageRowDetail serviceType={"opportunity"} /> */}
                  </PrivateRoute>
                }
              />

              <Route
                path="/cloudorgstructure"
                element={
                  <PrivateRoute>
                    <CloudOrgStructures />
                  </PrivateRoute>
                }
              />
              <Route
                path="/cloudorgstructure/:id"
                element={
                  <PrivateRoute>
                    <CloudOrgStructure />
                    {/* <TablePageRowDetail serviceType={"cloudOrgStructure"} /> */}
                  </PrivateRoute>
                }
              />

              <Route
                path="/auditlog"
                element={
                  <PrivateRoute>
                    <AuditLogs />
                  </PrivateRoute>
                }
              />
              <Route
                path="/auditlog/:id"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"auditLog"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/preference"
                element={
                  <PrivateRoute>
                    <Preferences />
                  </PrivateRoute>
                }
              />
              <Route
                path="/preference/:id"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"preference"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/state"
                element={
                  <PrivateRoute>
                    <StateSets />
                  </PrivateRoute>
                }
              />
              <Route
                path="/state/:id"
                element={
                  <PrivateRoute>
                    <StateSet />
                  </PrivateRoute>
                }
              />
              <Route
                path="/state/:id/:rowId"
                element={
                  <PrivateRoute>
                    <TablePageRowDetail serviceType={"stateSet"} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Job"
                element={
                  <PrivateRoute>
                    <Jobs />
                  </PrivateRoute>
                }
              />
              <Route
                path="/job/:id"
                element={
                  <PrivateRoute>
                    <Job />
                  </PrivateRoute>
                }
              />

              <Route
                path="/schedule"
                element={
                  <PrivateRoute>
                    <Schedules />
                  </PrivateRoute>
                }
              />
              <Route
                path="/schedule/:id"
                element={
                  <PrivateRoute>
                    <Schedule />
                  </PrivateRoute>
                }
              />

              <Route
                path="/sessions"
                element={
                  <PrivateRoute role="SuperAdmin,CustomerAdmin">
                    <UserSessions />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sessions/:id"
                element={
                  <PrivateRoute role="SuperAdmin,CustomerAdmin">
                    <UserSession />
                  </PrivateRoute>
                }
              />
              <Route
                path="/roles"
                element={
                  <PrivateRoute>
                    <UserRoles />
                  </PrivateRoute>
                }
              />
              <Route
                path="/roles/:id"
                element={
                  <PrivateRoute>
                    <UserRole />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user"
                element={
                  <PrivateRoute>
                    <UserList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user/:username"
                element={
                  <PrivateRoute>
                    <UserDetail />
                  </PrivateRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route path="/register" element={<Register />} />

              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboards />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/:id"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />

              <Route path="/faq" element={<FAQ />} />

              <Route path="/forbidden" element={<Forbidden />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/status" element={<Status />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/leadership" element={<Leadership />} />
              <Route path="/demo" element={<Demo />} />
              <Route path="/product" element={<Product />} />

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
