import { Box, Typography, Button, Popover } from "@mui/material";

import {
  RefreshOutlined,
  OpenInNewOutlined,
  CloudDownloadOutlined,
  DeleteOutline,
} from "@mui/icons-material";

import Header from "components/global/Header";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import FlexBetween from "components/global/FlexBetween";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import PageBlock from "components/global/PageBlock";
import TablePage from "components/global/TablePage";
import { now } from "moment";
import TableActionsButton from "components/global/TableActionsButton";
import NiceLink from "components/global/NiceLink";

import { renderColumn } from "common/renderColumn";
import {
  deleteAttachment,
  getAttachmentUi,
  getAttachments,
} from "slices/attachment";
import { useNavigate } from "react-router-dom";
import { clearMessage } from "slices/message";
import ConfirmationDialog from "components/global/ConformationDialogue";
import AttachmentCreate from "components/attachment/AttachmentCreate";

const Attachments = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const rows = useSelector((state) => state.attachment.data);
  const ui = useSelector((state) => state.attachment.Ui);

  useEffect(() => {
    //need to get the UI form the API
    dispatch(getAttachmentUi({}));
  }, [dispatch, rows]);

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const configRef = React.useRef(null);

  const handelOpenAddEdit = () => {
    dispatch(clearMessage());
    setAnchorEl(configRef.current);
  };

  //add edit statset
  const [anchorEl, setAnchorEl] = useState(null);
  const [areaHeight, setAreaHeight] = useState("60vh");
  const [areaWidth, setAreaWidth] = useState("30vw");

  const handelCloseAddEdit = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDelete = () => {
    const selected = selectionModel.length;

    if (selected > 1) {
      let message = "Are you sure you want to delete attachments:";

      selectionModel.forEach((item) => {
        const selectedAttachment = rows?.list.find(
          (attachment) => attachment.id === item
        );
        message += "\n" + selectedAttachment.name;
      });

      setDeleteAttachmentMessage(message);
      setOpenDialog(true);
    } else {
      const selectedAttachment = rows?.list.find(
        (attachment) => attachment.id === selectionModel[0]
      );
      setDeleteAttachmentMessage(
        "Are you sure you want to delete attachment\n" + selectedAttachment.name
      );
      setOpenDialog(true);
    }
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const [deleteAttachmentMessage, setDeleteAttachmentMessage] = useState(null);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setOpenInfoDialog(false);
  };

  const handleDialogConfirm = () => {
    dispatch(clearMessage());

    selectionModel.forEach((item) => {
      const id = item;
      dispatch(deleteAttachment({ id }));
    });
    setOpenDialog(false);
  };

  const [selectionModel, setSelectionModel] = React.useState([]);

  const handleOpen = () => {
    navigate("/attachment/" + selectionModel[0]);
  };

  const handleDownload = () => {
    const selected = selectionModel[0];

    const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_ATTACHMENT_URL;

    const newUrl = `${API_URL}${selected}`;
    console.log("Redirecting to:", newUrl);
    window.location.href = newUrl; // Redirect using window.location.href
  };

  const actionMenuItems = [
    {
      text: "Details",
      icon: <OpenInNewOutlined />,
      callBack: handleOpen,
      singleItemOnly: true,
    },
    {
      text: "Download",
      icon: <CloudDownloadOutlined />,
      callBack: handleDownload,
      singleItemOnly: true,
      disabled: false,
    },

    {
      text: "",
      icon: null,
      callBack: "",
      isDivider: true,
    },

    {
      text: "Delete",
      icon: <DeleteOutline />,
      callBack: handleDelete,
    },
  ];

  let columns = [];

  const processString = (inputString, row) => {
    const regex = /<([^>]+)>/g;

    inputString = inputString.replace(/_id/g, "id");

    const processedString = inputString.replace(regex, (match, placeholder) => {
      if (row.hasOwnProperty(placeholder)) {
        return row[placeholder];
      } else {
        return match;
      }
    });

    return processedString;
  };

  if (ui?.columns) {
    columns = ui.columns.map((column) => {
      const updatedColumn = { ...column }; // create a copy of the original object

      //debugger;

      if (updatedColumn.makeLink) {
        //modify to wrap in a nice link

        const originalRenderCell = updatedColumn.renderCell;

        updatedColumn.renderCell = ({ row }) => {
          const labelToUse =
            originalRenderCell !== ""
              ? renderColumn(column, row)
              : processString(updatedColumn.linkLabel, row);

          return (
            <NiceLink
              label={labelToUse}
              link={processString(updatedColumn.linkAddress, row)}
              tip={processString(updatedColumn.helperText, row)}
              makeBlue={updatedColumn.makeLinkBlue}
              showFollowIcon={updatedColumn.makeLinkIcon}
            />
          );
        };
      } else {
        if (updatedColumn.renderCell !== "") {
          // check if renderCell exists
          updatedColumn.renderCell = ({ row }) => renderColumn(column, row);
        }
      }

      return updatedColumn;
    });
  }

  return (
    <>
      <PageBlock>
        <SimpleBreadcrumbs />
        <FlexBetween>
          <Header title="Attachments" subtitle="" count={rows.total} />
          <FlexBetween gap=".5rem">
            <Button variant="outlined" color="primary" onClick={handelRefresh}>
              <RefreshOutlined />
            </Button>

            <TableActionsButton
              selectedRows={selectionModel}
              menuItems={actionMenuItems}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handelOpenAddEdit}
            >
              New Attachment
            </Button>
          </FlexBetween>
        </FlexBetween>

        <Box ref={configRef} />

        {rows && ui?.columns?.length > 0 ? (
          <TablePage
            dataRows={rows}
            dataColumns={columns}
            dataGetter={getAttachments}
            defaultColumnsVisability={ui.columnsVisability}
            defaultSort={ui.sort}
            refreshPoint={refreshPoint}
            selectionModel={selectionModel}
            selectionSetter={setSelectionModel}
            serviceType={"attachment"}
          />
        ) : (
          <Typography>Loading</Typography>
        )}
      </PageBlock>

      <ConfirmationDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Confirm Deletion"
        content={deleteAttachmentMessage}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handelCloseAddEdit}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box height={areaHeight} width={areaWidth}>
          <AttachmentCreate closerFunc={handelCloseAddEdit} />
        </Box>
      </Popover>
    </>
  );
};

export default Attachments;
