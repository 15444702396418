import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown,
  CheckOutlined,
  Clear,
  ClearOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDimensionValues } from "slices/dimensions";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import NiceBoolean from "./NiceBoolean";
import { renderType } from "common/renderType";
import reactToString, { processString } from "common/helpers";

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = "Click To Edit";

const EditableFieldString = ({
  fieldType,
  field,
  initialValue,
  tip,
  rows,
  required,
  serviceImplemenator,
  serviceType,
  serviceDimension,
  serviceFilter,
  lookUpLabel,
  displayValue,
  uiRenderType,
  currencyCode,
  onChange = () => {},
  setIsUpdating,
  isArray,
  ...props
}) => {
  const dispatch = useDispatch();

  const searchRef = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [newOption, setNewOption] = useState("");

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewOption(""); // Clear the new option input
  };

  const handleAddOption = () => {
    if (newOption.trim() && !options.includes(newOption.trim())) {
      setOptions((prevOptions) => [...prevOptions, newOption.trim()]);
      setValue((prev) => [...prev, newOption.trim()]); // Add the new option to value
      handleDialogClose(); // Close the dialog
    }
  };

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [buttonClicked, setButtonClicked] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const [value, setValue] = useState(null);

  //   const [value, setValue] = useState(() => {
  //     if (isArray) {
  //       if (isLookupBased)
  //         // If isArray is true, create an array of objects
  //         return initialValue.map(item => ({ value: item }));
  //     } else {

  //         return initialValue ;
  //     }
  // });

  const [inputValue, setInputValue] = React.useState("");

  const [options, setOptions] = useState([]);

  const [isLookupBased, setIsLookupBased] = useState(
    !!(serviceType && serviceImplemenator && serviceDimension) // Using !! to ensure boolean value
  );

  const values = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === serviceType &&
          dimension.implementor === serviceImplemenator &&
          dimension.field === serviceDimension
      )?.values
  );

  // useEffect(() => {
  //   //if (serviceImplemenator === "cataloguePage"){debugger}

  //   if (isFirstLoad && isLookupBased) {
  //     // Fetch the options if it's lookup based
  //     if (serviceType && serviceImplemenator && serviceDimension) {
  //       let search = {
  //         type: serviceType,
  //         implementor: serviceImplemenator,
  //         field: serviceDimension,
  //         lookUpLabel: lookUpLabel,
  //         filter: serviceFilter,
  //       };

  //       dispatch(getServiceDimensionValues({ search }));
  //       setIsFirstLoad(false);
  //     }
  //   } else {
  //     if (isLookupBased) {
  //       // For lookup-based fields, set options and value as object
  //       const newOptions = values?.map((value) => ({
  //         value: value.value,
  //         niceVersion: value.label,
  //       }));
  //       setOptions(newOptions);

  //       if (isArray) {
  //         // Create an array of options based on the array passed in as initialValue
  //         const selectedOptions = initialValue.map(
  //           (item) =>
  //             newOptions.find((option) => option.value === item) || {
  //               value: item,
  //               niceVersion: item,
  //             }
  //         );

  //         // Set the value as the array of matched options
  //         setValue(selectedOptions);
  //       } else {
  //         setValue(
  //           newOptions.find((option) => option.value === initialValue) || {
  //             value: "",
  //             niceVersion: "",
  //           }
  //         );
  //       }
  //     } else {
  //       if (isArray) {
  //         const newOptions = initialValue?.map((item) => item);
  //         setOptions(newOptions);
  //         setValue(initialValue); // Set the value to the initialValue directly
  //       } else {
  //         setValue(initialValue);
  //       }
  //     }
  //   }
  // }, [isFirstLoad, values, initialValue]);

  useEffect(() => {
    if (isFirstLoad && isLookupBased) {
      // Fetch the options if it's lookup based
      if (serviceType && serviceImplemenator && serviceDimension) {
        let search = {
          type: serviceType,
          implementor: serviceImplemenator,
          field: serviceDimension,
          lookUpLabel: lookUpLabel,
          filter: serviceFilter,
        };
  
        dispatch(getServiceDimensionValues({ search }));
        setIsFirstLoad(false);
      }
    } else {
      if (isLookupBased) {
        // For lookup-based fields, set options and value as object
        const newOptions = values?.map((value) => ({
          value: value.value,
          niceVersion: value.label,
        })) || []; // Default to empty array if no values
  
        setOptions(newOptions);
  
        if (isArray) {
          // Ensure that initialValue is an array, or default to an empty array
          const selectedOptions = (Array.isArray(initialValue) ? initialValue : []).map(
            (item) =>
              newOptions.find((option) => option.value === item) || {
                value: item,
                niceVersion: item,
              }
          );
  
          // Set the value as the array of matched options
          setValue(selectedOptions);
        } else {
          // Handle the case where initialValue is null/undefined or not an array
          setValue(
            newOptions.find((option) => option.value === initialValue) || {
              value: "",
              niceVersion: "",
            }
          );
        }
      } else {
        if (isArray) {
          // Ensure initialValue is an array or default to an empty array
          const newOptions = Array.isArray(initialValue) ? initialValue.map((item) => item) : [];
          setOptions(newOptions);
  
          // Handle case where initialValue is null/undefined or not an array
          setValue(Array.isArray(initialValue) ? initialValue : []); // Set value to an empty array if initialValue is not an array
        } else {
          // For non-array fields, set value to initialValue or an empty string
          setValue(initialValue ?? ""); // Use nullish coalescing operator to handle null/undefined
        }
      }
    }
  }, [isFirstLoad, values, initialValue]);
  
  

  const handleChange = (e) => setValue(e.target.value);

  const handelEditMode = () => {
    setEditing(true);

    if (setIsUpdating !== undefined) {
      setIsUpdating(true);
    }
  };

  const handelDisplayMode = () => {
    setEditing(false);
    if (setIsUpdating !== undefined) {
      setIsUpdating(false);
    }
  };

  const handelSave = (e) => {
    if (isArray) {
      if (isLookupBased) {
        const selectedValues = value.map((item) => item.value);

        // Check if the array is empty and send the clear command if it is
        if (selectedValues.length === 0) {
          onChange(["!CLEARCLEARCLEAR!"]);
        } else {
          onChange(selectedValues); // Pass array of values for lookup fields
        }
      } else {
        
        const selectedValues = value.map((item) => item);

        // Check if the array is empty and send the clear command if it is
        if (selectedValues.length === 0) {
          onChange(["!CLEARCLEARCLEAR!"]);
        } else {
          onChange(selectedValues); // Pass array of values for lookup fields
        }
      }
    } else {
      if (isLookupBased) {
        onChange(value?.value); // For lookup-based, pass the `value` property
      } else {
        onChange(value); // For non-lookup, pass the simple string
      }
    }
    handelDisplayMode();
  };

  const handelReset = (e) => {
    setValue(initialValue);
    handelDisplayMode();
  };

  if (field === "savingModels") {
    console.log(
      "Field: ",
      field,
      "\nService Type: ",
      serviceType,
      "\nService Implemenator: ",
      serviceImplemenator,
      "\nDimensionID: ",
      serviceDimension,
      "\nFilter: ",
      serviceFilter,
      "\nOptions: ",
      options,
      "\nCurrent Value: ",
      value,
      "\nInitial Value: ",
      initialValue,
      "\nin lookup mode : ",
      isLookupBased,
      "\nin Array mode : ",
      isArray,
      "\nFirst Load",
      isFirstLoad
    );
  }

  if (isEditing) {
    if (isLookupBased) {
      // Render Autocomplete if isLookupBased is true
      return (
        <Box
          display={"flex"}
          alignContent={"start"}
          flexDirection={"row"}
          gap=".4rem"
          border={0}
          p={".2rem"}
        >
          <Autocomplete
            multiple={isArray} // Allow multiple selections if isArray is true
            ref={searchRef}
            value={value}
            onChange={(event, newValue) => {
              //debugger
              if (isArray) {
                // For multiple selections, map over the selected objects and extract 'value'
                setValue(newValue.map((option) => option));
              } else {
                // For single selection, just extract 'value'
                setValue(newValue || "");
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="search"
            options={options}
            disableCloseOnSelect={isArray} // Keep the dropdown open when selecting multiple values
            sx={{ mb: "0.4rem", flex: 1 }}
            // This is important: Helps Autocomplete match the selected value with the options

            renderInput={(params) => (
              <TextField {...params} variant="standard" size="small" />
            )}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} key={option.value} value={option.value}>
                {isArray && <Checkbox checked={selected} />}
                <ListItemText primary={option.niceVersion || option.value} />
              </MenuItem>
            )}
            getOptionLabel={(option) => {
              return option.niceVersion ? option.niceVersion : option.value;
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderTags={(selected) =>
              Array.isArray(selected)
                ? selected.map((v) => v.niceVersion).join(", ")
                : ""
            } // Display selected values as a comma-separated string
          />

          <Stack gap=".2rem" direction="row">
            <IconButton
              size="small"
              fontSize="small"
              onClick={handelSave}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <CheckOutlined fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handelReset()}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <ClearOutlined fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      );
    } else {
      // Not lookup-based: standard TextField or multi-value autocomplete
      return (
        <Box
          display={"flex"}
          alignContent={"start"}
          flexDirection={"row"}
          gap=".4rem"
          border={0}
          p={".2rem"}
        >
          {isArray ? (
            <>
              <Autocomplete
                multiple
                value={value}
                //  onChange={(event, newValue) => {
                //    setValue(newValue);
                //  }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                  // Check if "Add New" is in the newValue
                  if (newValue.includes("Add New")) {
                    handleDialogOpen(); // Open dialog when "Add New" is selected
                    setValue(newValue.filter((v) => v !== "Add New")); // Remove "Add New" from value
                  } else {
                    setValue(newValue);
                  }
                }}
                id="multi-add"
                sx={{ mb: "0.4rem", flex: 1 }}
                options={[...options, "Add New"]} // Include "Add New" option
                renderInput={(params) => (
                  <TextField {...params} variant="standard" size="small" />
                )}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} key={option} value={option}>
                    <Checkbox checked={selected} />
                    <ListItemText primary={option} />
                  </MenuItem>
                )}
                renderTags={(selected) => selected.join(", ")} // Display selected values
              />

              {/* Dialog for adding new option */}
              <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>New Value</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please enter the new value, it will be selected for you on add.
                  </DialogContentText>

                  <TextField
                    autoFocus
                    margin="dense"
                    label="New Value"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newOption}
                    onChange={(e) => setNewOption(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleDialogClose}
                    variant="outlined"
                    color="error"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleAddOption}
                    color="primary"
                    variant="contained"
                  >
                    Add
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <TextField
              size="small"
              variant="standard"
              type={fieldType}
              value={value}
              rows={rows}
              multiline={rows > 0}
              onChange={handleChange}
              sx={{ flex: 1 }} // Add this to ensure it takes available width
            />
          )}

          <Stack gap=".2rem" direction="row">
            <IconButton
              size="small"
              onClick={handelSave}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <CheckOutlined fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handelReset()}
              onMouseDown={() => setButtonClicked(true)}
              onMouseUp={() => setButtonClicked(false)}
            >
              <ClearOutlined fontSize="small" />
            </IconButton>
          </Stack>
        </Box>
      );
    }
  }

  return (
    <Tooltip title={tip} placement="bottom-start">
      <Stack
        gap=".2rem"
        direction="row"
        alignItems={"flex-start"}
        p={"2px"}
        onClick={handelEditMode}
        // sx={{
        //   borderRadius: 1,
        //   cursor: "pointer",
        //   border: "1px solid transparent", // Initially transparent border
        //   "&:hover": {
        //     border: "1px solid #000", // Change border color on hover
        //   },
        // }}

        sx={{
          cursor: "pointer",
        }}
      >
        {displayValue}

        <IconButton
          size="small"
          //onClick={() => handelReset()}
          onMouseDown={() => setButtonClicked(true)}
          onMouseUp={() => setButtonClicked(false)}
        >
          <EditOutlined fontSize="small" />
        </IconButton>
      </Stack>
    </Tooltip>
  );
};

export default EditableFieldString;
