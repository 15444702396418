import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import DimensionSevice from "services/dimension.service";

export const getServiceDimensionValues = createAsyncThunk(
  "Dimension/getValues",
  async ({ search }, thunkAPI) => {
    try {
      const data = await DimensionSevice.getDimenionValues(search);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: "error" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  dimensions: [],
  loading: false,
};

const DimensionSlice = createSlice({
  name: "Dimensions",
  initialState,

  extraReducers: {
    //Column Reducers

    [getServiceDimensionValues.pending]: (state, action) => {
      //debugger;

      const name = action.meta.arg.search?.name;
      const implementor = action.meta.arg.search?.implementor;
      const type = action.meta.arg.search?.type;
      const field = action.meta.arg.search?.field;

      // Find the index of the record
      const index = state.dimensions.findIndex(
        (item) =>
          item.name === name && 
          item.type === type &&
          item.implementor === implementor &&
          item.field === field
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.dimensions[index].loading = true;
      } else {
        // If no match found, insert a new record with loading set to true
        state.dimensions.push({
          name,
          type,
          implementor,
          field,
          values: [],
          loading: true,
        });
      }
    },
    [getServiceDimensionValues.fulfilled]: (state, action) => {
      //debugger;

      const name = action.meta.arg.search?.name;
      const implementor = action.meta.arg.search?.implementor;
      const type = action.meta.arg.search?.type;
      const field = action.meta.arg.search?.field;

      //const values = action.payload?.data?.lookUpLabels;
      const values = action.payload?.data?.lookUpLabels;

      

      // Find the index of the record
      const index = state.dimensions.findIndex(
        (item) =>
          item.name === name && 
          item.type === type &&
          item.implementor === implementor &&
          item.field === field
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.dimensions[index].values = values;
        state.dimensions[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.dimensions.push({
          name: name,
          type: type,
          implementor: implementor,
          field: field,
          values: values,
          loading: false,
        });
      }
    },
    [getServiceDimensionValues.rejected]: (state, action) => {
      //debugger

      const name = action.meta.arg.search?.name;
      const implementor = action.meta.arg.search?.implementor;
      const type = action.meta.arg.search?.type;
      const field = action.meta.arg.search?.field;

      const values = []

      // Find the index of the record
      const index = state.dimensions.findIndex(
        (item) =>
          item.name === name && 
          item.type === type &&
          item.implementor === implementor &&
          item.field === field
      );

      if (index !== -1) {
        // If record found, update loading to true
        state.dimensions[index].values = values;
        state.dimensions[index].loading = false;
      } else {
        // If no match found, insert a new record with loading set to true
        state.dimensions.push({
          name: name,
          type: type,
          implementor: implementor,
          field: field,
          values: values,
          loading: false,
        });
      }
    },
  },
});

const { reducer, actions } = DimensionSlice;

export const { setDimensionRenderState } = actions;

export default reducer;
