import { useTheme } from "@emotion/react";
import { Box, Link } from "@mui/material";
import { DynamicIcon } from "common/dynamicIcon";
import { services } from "common/Services.js";
import { cloneElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { getServiceTypes } from "slices/services";

const NiceService = (props) => {
  //console.log("date: ", moment(props.children))

  const theme = useTheme();
  const dispatch = useDispatch();

  const serviceTypes = useSelector((state) => state.services.serviceTypes )


  const wantedService = props?.wantedService;
  const implementorId = props?.implementorId;
  const objectId = props?.children;

  useEffect(() => {
    // Dispatch getServiceTypes if serviceTypes array is empty
    if (serviceTypes.length === 0) {
      dispatch(getServiceTypes());
    }
  }, [dispatch, serviceTypes]);


  if (wantedService) {
    //lookup the service
    const thisService = serviceTypes.find(
      (service) => service.serviceType === wantedService
    );

    

    let linkTo = thisService?.getUrl + objectId;

    // switch (wantedService){

    //   case "copilot":
    //     //get the name of the chat

    //   break:
    //   default:

    // }

    //thisService.name + " - " + objectId

    if (thisService) {
      return (
        <Box display={"flex"} flexDirection={"row"} gap={"1rem"}>
          {/* {cloneElement(thisService.icon, {
            sx: { color: theme.palette.primary.main },
          })} */}

<DynamicIcon iconName={thisService.icon} />

          <Link
            to={linkTo}
            component={RouterLink}
            underline="hover"
            fontSize="inherit"
          >
            {thisService.name + " - " + objectId}
          </Link>
        </Box>
      );
    } else {
      return objectId;
    }
  } else {
    return objectId;
  }
};

export default NiceService;
