import Moment from "react-moment";
import { Typography, Box, useTheme, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";

import KeyVal from "./KeyVal";

const NiceArrayCell = (props) => {
  const cellObject = props.children;

  if (Array.isArray(cellObject)) {
    return (
      <Box>
        {cellObject.map((item, index) => (
          <Stack key={index} direction={"row"} spacing={2}>
            {/* Check if item is an object */}
            {typeof item === "object" && !Array.isArray(item) ? (
              Object.keys(item).map((fieldName) => (
                <KeyVal
                  key={fieldName}
                  label={fieldName}
                  value={item[fieldName]}
                  correctLabel={true}
                  labelBold={true}
                />
              ))
            ) : (
              // Handle primitive types (strings, numbers, etc.)
              <Box>
              {item}
              </Box>

              
            )}
          </Stack>
        ))}
      </Box>
    );
  } else {
    return <Box>Not an Array</Box>;
  }
};


export default NiceArrayCell;
